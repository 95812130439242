//*** import react library and components
import React, { useRef, useState} from "react";
import { Create_mqtt_command_message } from "./PlantRobotOperation";

//Export default Joystick;
const Joystick = () => {
  const joystickRef = useRef();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [direction, setDirection] = useState(null);
  const [stop, setStop] = useState(null);
  const [isJoystickActive, setIsJoystickActive] = useState(true); 
  const handleRadius = '40px';
  let touchId = null;

  const handleMouseDown = (event) => {
    event.preventDefault();
    setIsJoystickActive(true);
  };

  const handleMouseUp = (event) => {
    event.preventDefault();
    setIsJoystickActive(false);
    setPosition({ x: 0, y: 0 });
    console.log(stop);
    Create_mqtt_command_message("Turn", "D")
    Create_mqtt_command_message("Command", "S")
    //setMoveDirection();
  };
   
   const handleTouchStart = (event) => {
        event.preventDefault();
       if (touchId === null) {
           touchId = event.changedTouches[0].identifier;
           setIsJoystickActive(true);
       }
     };

    const handleTouchEnd = (event) => {
        event.preventDefault();
        setIsJoystickActive(false);
        setPosition({ x: 0, y: 0 });
        console.log(stop);
        Create_mqtt_command_message("Turn", "D")
        Create_mqtt_command_message("Command", "S")
        touchId = null;
  };

    function setMoveDirection() {
    const threshold = 0.1;
    if (Math.abs(position.x) < threshold && Math.abs(position.y) < threshold) {
        setDirection("stop");
        }
        //alert("hello-3456");
    if (Math.abs(position.x) > Math.abs(position.y)) {
      if (position.x > 0) {
          setDirection("right");
          Create_mqtt_command_message("Turn", "R")
      } else {
          setDirection("left");
          Create_mqtt_command_message("Turn", "L")
        }
       // alert("hello-3457");
    } else if (Math.abs(position.x) < Math.abs(position.y)) {
      if (position.y > 0) {
          setDirection("backward");
          Create_mqtt_command_message("Command", "B")
      } else {
          setDirection("forward");
          Create_mqtt_command_message("Command", "F")
      }
        }
      // alert("hello-345999");
    console.log(direction);
  }

    const handleMove = (event) => {
      event.preventDefault();
    
        
   if (isJoystickActive) { 
     const joystick = joystickRef.current;
     const joystickRect = joystick.getBoundingClientRect();

     const joystickCenter = {
         x: joystickRect.left + joystickRect.width / 2,
         y: joystickRect.top + joystickRect.height / 2,
     };
     let x = event.clientX - joystickCenter.x;
     let y = event.clientY - joystickCenter.y;

     let clientX = event.clientX;
     let clientY = event.clientY;
     if (touchId !== null) {
         for (let i = 0; i < event.changedTouches.length; i++) {
             if (event.changedTouches[i].identifier === this.touchId) {
                 clientX = event.changedTouches[i].clientX;
                 clientY = event.changedTouches[i].clientY;
                 //const x = clientX - joystickRect.left - 70 + 40; //this.options.width / 2 + this.options.handleRadius;
                 //const y = clientY - joystickRect.top - 70 + 40; //this.options.height / 2 + this.options.handleRadius;
                  x = clientX - joystickCenter.x;
                  y = clientY - joystickCenter.y;
                 break;
             }
         }
     }
      const distance = Math.sqrt(x * x + y * y);
      const angle = Math.atan2(y, x);
      const maxDistance = joystickRect.width / 2;
      const limitedDistance = Math.min(distance, maxDistance);
      const limitedX = limitedDistance * Math.cos(angle);
      const limitedY = limitedDistance * Math.sin(angle);
      setPosition({ x: limitedX / maxDistance, y: limitedY / maxDistance });
      console.log("X: " + position.x + "\nY: " + position.y);
      //alert("Hello-1123");
      setMoveDirection();
    } else {
      setStop("stop");
    }
  };

    //useEffect(() => {
    //    window.addEventListener('mousemove', handleMove);
    //    window.addEventListener('touchmove', handleTouchEnd);
    //    window.addEventListener('mouseup', handleMouseUp);
    //    window.addEventListener('mousedown', handleMouseDown);
    //    window.addEventListener('touchstart', handleTouchStart);
    //    window.addEventListener('touchend', handleTouchEnd);

    //},)
  return (
    <div 
      ref={joystickRef}
      style={{
        width: "140px",
        height: "140px",
        borderRadius: "50%",
        backgroundColor: "#000",
        opacity: 0.5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMove}
      onTouchMove={handleMove}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
    <div id="stick1"
        style={{
          width: "70px",
          height: "70px",
          borderRadius: "50%",
          backgroundColor: "#fff",
          opacity: 1,
          position: "absolute",
          top: `${position.y * 70 + 35}px`,
          left: `${position.x * 70 + 35}px`,
        }}
      />
    </div>
  );
};
export default Joystick;
