import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
/*import "./App.css";*/
import "../../Assets/Styles/Map1.css";
export function TestMap() {
    const { isLoaded } = useLoadScript({
        //AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU// Milind google map key
        googleMapsApiKey:"AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU", //process.env.REACT_APP_GOOGLE_API_KEY,
    });
    const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

    return (
        <div className="App">
            {!isLoaded ? (
                <h1>Loading...</h1>
            ) : (
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={10}
                />
            )}
        </div>
    );
};

export default TestMap;
