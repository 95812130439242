//*** Import React Library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Table from "react-data-table-component";
import { ChartCurrentPlantLevelGrassCutting } from "./ChartCurrentPlantLevelGrassCutting";
import Button from "react-bootstrap/Button";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import * as hdf5 from 'jsfive';
//*** Chart Library ***//
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";

const columns = [
    { name: "Sr. No.", selector: (row) => row.id + 1, sortable: true },
    { name: "Working Date", selector: (row) => row.workingDate, sortable: true },
    { name: "Area Covered (Cycles)", selector: (row) => row.areaCovered, sortable: true },
];
const labels = [];
const chartData = [];
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const CurrentPlantLevelGrassCutting = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([])
    const [List, setList] = useState([])
    const [SelectedList, setSelectedList] = useState([])
    const [MasterChecked, setMasterChecked] = useState(false)
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    //const contextActions = (
    //    <button onClick={() => alert(data.map((r) => r.robotID))}>
    //        Show Selected Rows
    //    </button>
    //);
    fetch("@C:\Users\analy\Downloads\SMAP_L2_SM_P_47933_A_20240122T000106_R19240_001.hdf5")
        .then(function (response) {
            return response.arrayBuffer()
        })
        .then(function (buffer) {
         //   alert("jfhghf");
            let datafilename ="SMAP_L2_SM_P_47933_A_20240122T000106_R19240_001.hdf5";
          //  alert(datafilename);
            var f = new hdf5.File(buffer, datafilename);
          // alert(f);
            // do something with f;
            let g = f.get('group');
            //alert(g);
            // let d = f.get('group/dataset');
            // let v = d.value;
            // let a = d.attrs;
        });
    useEffect(() => {
        fetchData();
        

    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        const response = await Axios("/PlantLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        //const response = await Axios("/PlantLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        setData(response.data)
        setList(response.data)
        data.map((user) => {
            // Create_broadcasting_command_message(user.robotID); // Send Robot ID for Broadcast
        });
    }
    return (
        <>
          {hasJWTToken() ? (
              <div>
                <h3 style={{ margin: "2%", marginLeft: "2%" }}>Current Plant Level Grass Cutting</h3>
                <div
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: "5px",
                        margin: "2%",
                        backgroundColor: "#fff",
                    }}
                  >
                    <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "92%" }} >
                        <div className="row dvscroll">
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    placeholder="Search Results"
                                    style={{
                                        width: "20%",
                                        height: "30px",
                                        margin: "10px",
                                        float: "right",
                                        border: "none",
                                        padding: "1.5%",
                                        borderRadius: "5px",
                                    }}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <p></p>
                                <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                    search={true}
                                    columns={columns}
                                    data={filteredData}
                                    highlightOnHover={true}
                                    customStyles={customStyles}
                                >
                                </DataTable>
                            </div>
                            <div className="col-sm-6">
                                <ChartCurrentPlantLevelGrassCutting></ChartCurrentPlantLevelGrassCutting>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            ) :
                (
                    <Route to={{ pathname: '#/login' }} />
                )
            }
        </>
    );
};
