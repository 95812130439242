//*** import react lib
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Table from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";

//*** Global variables 
var PlantID = "1";
const RobotID = "FB001";
var Current_data = null;
//***End Global Variables
let _plantID = (new URLSearchParams(window.location.search)).get("pid")
PlantID = _plantID;

//*** Create table coloumns
const columns = [
    /*{ name:"Plant ID", selector: (row) => row.plantID, sortable:true },*/
    { name: "Date & Time (hh:mm:ss)", selector: (row) => row.workingDate, sortable: true },
    { name: "Block", selector: (row) => row.blockName, sortable: true },
    { name: "Row", selector: (row) => row.rowName, sortable: true },
    { name: "Robot", selector: (row) => row.robotName, sortable: true },
    { name: "Speed", selector: (row) => row.speed, sortable: true },
    { name: "Grass Cutter", selector: (row) => row.grassCutter, sortable: true },
    { name: "Sprayer", selector: (row) => row.sprayer, sortable: true },
    //{ name: "Sowing", selector: (row) => row.sowing, sortable: true },
    //{ name: "Harvesting", selector: (row) => row.harvesting, sortable: true },
    { name: "Command", selector: (row) => row.currentCommandStatus, sortable: true },
   /* { name: "Command ID", selector: (row) => row.commandID, sortable: true },*/
    { name: "CB", selector: (row) => row.controllerBatteryStatus, sortable: true },
    { name: "MB", selector: (row) => row.motorBatteryStatus, sortable: true },
  /*  { name: "Connected Status", selector: (row) => row.broadcastStatus, sortable: true },*/
   
];

//*** Create custom styles ofreact tables
const customStyles = {
  table: {
    style: {
         border: "none",
         fontSize: "100%"
    },
  },
  header: {
    backgroundColor: "transparent",
  },
  rows: {
    style: {
          backgroundColor: "transparent",
    },
  },
  headRow: {
    style: {
          backgroundColor: "transparent",
          fontSize: "110%"
    },
  },
  pagination: {
    style: {
          backgroundColor: "transparent",
          fontWeight: "600",
    },
  },
  headCells: {
    style: {
      borderBottom: "none",
      fontWeight: "700",
      fontSize: "110%"
    },
  },
};

export const PlantRobotsOperationStatus = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([])
  const [List, setList] = useState([])
  const [SelectedList, setSelectedList] = useState([])
  const [MasterChecked, setMasterChecked] = useState(false)
  const handleSearch = (event) => {
        setSearchTerm(event.target.value);
         let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
       
        if (event.target.value != null) {
            setList(filteredData);
        }
      
        if (event.target.value == "") {
            setList(data);
        }
     
    };

  const filteredData = List.filter((event) =>
      Object.values(event).some(
      (value) => typeof value === "string" && value.includes(searchTerm)
    )
    );
    const handleRowSelected = (rows) => {
      setData(rows.selectedRows);
  };
   const contextActions = (
    <button onClick={() => alert(data.map((r) => r.robotID))}>
      Show Selected Rows
    </button>
    );
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //const response = await Axios("/PlantRobotsOperationStatus?pid=" + plant_id + "&rid=0&rt=1&rbt=3&ct=0&uid=1");
        //const response = await Axios("/PlantRobotsOperationStatus?pid=" + plant_id + "&rt=multiple");
        const response = await Axios("/PlantRobotsOperationStatus?pid=" + plant_id + "&rid=0&rt=1&rbt=3&ct=0&uid=1");
        setList(response.data)
    }
  return (
      <>
          <h3 style={{ margin: "2%" , marginLeft: "2%" }} >Plant Robot's Operation Status</h3>
      <div
        style={{
          border: "1px solid #c0c0c0",
          borderRadius: "5px",
          margin: "2%",
          backgroundColor: "#fff",
        }}
      >
              <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "92%" }} >
                  <div className="row dvscroll">
                      <div className="col-sm-12">
                          <input
                              type="text"
                              placeholder="Search Results"
                              style={{
                                  width: "20%",
                                  height: "30px",
                                  margin: "10px",
                                  float: "right",
                                  border: "none",
                                  padding: "1.5%",
                                  borderRadius: "5px",
                              }}
                              value={searchTerm}
                              onChange={handleSearch}
                          />
                          <p></p>
                          <DataTable className="table .table-sm table-hover"  sort={true} pagination={true}
                              search={true} 
                              columns={columns}
                              data={filteredData}
                              highlightOnHover={true}
                              customStyles={customStyles}
                          >
                              <thead scope="row">
                                  <tr>
                                      <th scope="col">Sr. No</th>
                                      <th scope="col">Block name</th>
                                      <th scope="col">Row Name</th>
                                      <th scope="col">Robot Name</th>
                                      <th scope="col">Speed</th>
                                      <th scope="col">Grass Cutter</th>
                                      <th scope="col">Sprayer</th>
                                      <th scope="col">Command</th>
                                      {/*<th scope="col">Command ID</th>*/}
                                      <th scope="col">CB</th>
                                      <th scope="col">MB</th>
                                      <th scope="col">Broadcast Status</th>
                                      <th scope="col"> Date & Time</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  {List.map((user) => (
                                      <tr key={user.id} className={user.selected ? "selected" : ""}>
                                         {/* <td>{user.id +1}</td>*/}
                                          <td>{user.blockName}</td>
                                          <td>{user.rowName}</td>
                                          <td >{user.robotName}</td>
                                          <td >{user.speed}</td>
                                          <td>{user.grassCutter}</td>
                                          <td>{user.sprayer}</td>
                                          <td>{user.currentCommandStatus}</td>
                                          <th>{user.commandID}</th>
                                          {/*<td>{user.sowing}</td>*/}
                                          {/*<td>{user.harvesting}</td>*/}
                                          <td>{user.controllerBatteryStatus}</td>
                                          <td>{user.motorBatteryStatus}</td>
                                          <td>{user.broadcastStatus}</td>
                                          <td>{user.workingDate}</td>
                                      </tr>
                                  ))}
                              </tbody>
                          </DataTable>
                      </div>
                  </div>
              </div>
          </div>
  </>
  );
};
