import React from 'react';
import mqtt from "mqtt/dist/mqtt";
import Axios from 'axios';
//import { GetPageRefresh } from "../RobotOperation/RobotOperation";
//MQTT code
//Following Main url which is working on local
//import ResponsiveEmbed from 'react-bootstrap/lib/ResponsiveEmbed';
///***Use anyone MQTT Broker
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
//const url = 'ws://broker.hivemq.com:8000/mqtt'
const url = 'wss://broker.hivemq.com:8884/mqtt'
//*** Global variables ***//
var PlantID = sessionStorage.getItem("user_plantid");
const sruserID = sessionStorage.getItem("user_id");
const MQTT_Subscribe_Topic = "zylv4u8fun1";
const MQTT_Receiver_Topic = "e1ypuwron21";
const ClienID = Math.floor(100000000000 + Math.random() * 900000000000);
var SubscribtionStatus = false;
var BroadcastingStatus = false;
var CameraCaptureStatus = false;
var IPAddress;
var RobotID;
var BroadcastingStatus = false;
var CameraCaptureStatus = false;
var Current_command_status = null;
var Current_motor_speed_status = null;
var Current_turn_status = null;
var Current_grass_cutter_status = null;
var Current_sprayer_status = null;
var Current_sowing_status = null;
var Current_haversting_status = null;
var Current_data = null;
var current_lat = null;
var current_lon = null;
//*** End Global Variables

///***
// * for Support url -https://www.emqx.com/en/blog/mqtt-js-tutorial
// * Browser
// * Using MQTT over WebSocket with ws and wss protocols
// * EMQX's ws connection default port is 8083, wss is 8084
// * Note that you need to add a path after the connection address, such as /mqtt
// */
//const url = 'ws://broker.emqx.io:8083/mqtt'
///***

// * Node.js
// * Using MQTT over TCP with mqtt and mqtts protocols
// * EMQX's mqtt connection default port is 1883, mqtts is 8883
// */
//const url = 'mqtt://broker.emqx.io:1883'

//**** Set MQTT options parameters ***//
const options = {
    //clean session,
    clean: true,
    keepalive: 1060,
    connectTimeout: 500000,
    //*** Authentication
    clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`, //can be changed for something more specific if you need it
    //userName: 'emqx_test',
    //password: 'emqx_test',
}
//*** Step-1 ***//
//*** Connect To MQTT broker ***//
var client1 = mqtt.connect(url, options)
client1 = new mqtt.connect(url, options)
client1.on('connect', function (err) {
    if (err) {
        //alert("not connect");
        console.log("Not Connected To MQTT Broker");
    }
    if (!err) {
        //alert("connected");
        console.log("Connected To MQTT Broker");
    }
});

//*** Create broadcast frame message ***//
export const Create_broadcasting_command_message = (_robotID) => {
    //*** Send Broadcast command to cloud database ***//
     const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + _robotID + "&rcs=Disconnected");
    //const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + _robotID + "&rcs=Disconnected");
    var obj = new Object();
    obj.FID = _robotID; //RobotID;
    var Broadcasting_command_message = JSON.stringify(obj);
    Send_broadcasting_command_message_to_robot(Broadcasting_command_message, _robotID);
};

//*** Step-3 ***//
/***Send MQTT broadcasting message to Robots ***/
function Send_broadcasting_command_message_to_robot(Broadcasting_command_msg, robotID) {
    try {
        Client_subscribe_to_mqtt_topic();
        //client1.subscribe(MQTT_Subscribe_Topic, function (err) {
        //    if (!err) {
                //*** Send MQTT Broadcasting Message to Robot ***//
                client1.publish(MQTT_Subscribe_Topic, Broadcasting_command_msg)
                console.log("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
                //alert("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
                BroadcastingStatus = true;
           // }
        //})
    }
    catch (e) {
        console.log("Step-1 (Broadcasting) Error: " + e.message + "");
    }
}
//*** Subscribe to MQTT topic
export const Client_subscribe_to_mqtt_topic = () => {
    var subscribe_topic;
    try {
        //*** Subescribe to Pblish Topic 'zylv4u8fun' ***//
        client1.subscribe(MQTT_Subscribe_Topic, function (err) {
            if (!err) {
                console.log('Client Subscribed to MQTT Pblish Topic')
                SubscribtionStatus = true;
            }
            if (err) {
                console.log('Client not Subscribed to MQTT Pblish Topic')
                SubscribtionStatus = false;
            }
        })
        subscribe_topic = MQTT_Subscribe_Topic;
        //*** Subscribe to message receiver topic ***//
        client1.subscribe(MQTT_Receiver_Topic, function (err1) {
            if (!err1) {
                console.log('Client Subscribed to MQTT receiver topic')
                SubscribtionStatus = true;
            }
            if (err1) {
                console.log('Client not Subscribed to MQTT receiver topic')
                client1.subscribe(MQTT_Receiver_Topic);
                SubscribtionStatus = false;
            }
        })
        subscribe_topic = subscribe_topic + "," + MQTT_Subscribe_Topic;
        return SubscribtionStatus;
    }
    catch (e) {
        SubscribtionStatus = false;
        console.log("Error to Subscribe Task: " + e.message + "");
    }
};
//**** Receive frame from Robots to MQTT and then MQTT to Bhuhit UI****//
client1.on('message', function (topic, message) {
   // alert(topic + "," + message);
    //*** message is Buffer ***//
    var obj = JSON.parse(message);
    if (topic === MQTT_Receiver_Topic) {
        //setItemChecked(false);
        //*** Add robot connected status (status from robots) in cloud database ***//
        if (obj.FID != null && obj.IP != null) {
            IPAddress = obj.IP;
            RobotID = obj.FID;
            CaptureCamera(IPAddress)
           // alert(IPAddress);
            const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&rcs=Connected");
            //const bcresponse = Axios("/RobotBroadcast/addbcs?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&rcs=Connected");
        }

        //*** Check receving command message ***/
        if (obj.FID != null && obj.Cmd != null) {
            //*** Step-2 and Step-3 have executed. Operator have connected with Robots***//
            /*** Step-3 (Connectivity to Robot): You have connected to Robot and have completed step 3 /6. ***/
            console.log("Step-2 (Broadcasting): Operator have received Robot ID '" + obj.FID + "' from Robot and have completed step 2/6.");// message.payloading
            console.log("Step-3 (Connectivity to Robot): You are connected to Robot ID '" + obj.FID + "'  and have completed step 3 /6.");
            if (obj.Cmd != null) Current_command_status = obj.Cmd;
            if (obj.Spd != null) Current_motor_speed_status = obj.Spd;
            if (obj.Turn != null) Current_turn_status = obj.Turn;
            if (obj.GC != null) Current_grass_cutter_status = obj.GC;
            if (obj.SP != null) Current_sprayer_status = obj.SP;
           //**** Add command status (status from robots) in cloud database ****/
            if (obj.FID != null && obj.Cmd != null) {
                let rescmdstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&lat=0&lon=0&cb=0&mb=0&cid=1");
                //let rescmdstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&lat=0&lon=0&cb=0&mb=0&cid=1");
            }

            if (obj.FID != null && obj.OPN != null) {
                let resoparationstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=OFF&tun=D&spd=0&gc=0&sp=0&lat=0&lon=0&cb=0&mb=0&cid=1");
            }
        }
        //*** Update current lat and lon status from robots in cloud database ****/
        if (obj.FID != null && obj.lat != null && obj.lon != null) {
            let resplatlonstatus = Axios("/PlantRobotsLocation?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&lat=" + obj.lat + "&lon=" + obj.lon + "&det=" + obj.Det + "");
            //let resplatlonstatus = Axios("/PlantRobotsLocation?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&lat=" + obj.lat + "&lon=" + obj.lon + "&det=" + obj.Det + "");
        }
       // GetPageRefresh();
    }
});

//**** To check error ****//
client1.on('error', function (err) {
    alert(err.message);
});
export const CaptureCamera = () => {
    var ip = null
    ip = IPAddress 
    // alert(IPAddress + "Capture Camera");
    if (IPAddress === null) ip = "http://192.168.137.150:8000" //document.getElementById("ipcamera").setAttribute("src", "http://192.168.137.150:8000");
    return ip
};



const stop_operation = () => {
    //*** Send stop operation frame to robots ***//
    //*** Send_command_to_selected_robots("OFF")
    //*** Unsubscribe topic ***//
    client1.unsubscribe("zylv4u8fun11", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic zylv4u8fun1')
            client1.end()
            Set_Variables_Status();
        }
    })
    client1.unsubscribe("e1ypuwron21", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic e1ypuwron21')
            client1.end()
            Set_Variables_Status();
        }
    })
    //*** End clients ***//
    client1.end()
    //*** Clear all global variables ***//
    Set_Variables_Status();
};

//*** Set global variables status null or false ***//
function Set_Variables_Status() {
    BroadcastingStatus = false;
    CameraCaptureStatus = false;
    Current_command_status = null;
    Current_turn_status = null;
    Current_motor_speed_status = null;
    Current_grass_cutter_status = null;
    Current_sprayer_status = null;
}

//*** Send MQTT frame message to robots ***//
export const send_command_message_to_robot=(commandmessage)=> {
    try {
        client1.publish(MQTT_Subscribe_Topic, commandmessage)
        console.log(commandmessage)
    }
    catch (e) {
        console.log("Step-5 (Command Processing) Error: " + e.message + "");
    }
}
export function MQTT() {
    return (
        <div></div>
    );
};
export default MQTT;