//*** Import React Library
import axios from "axios";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";

//*** Export current chart of robot level spraying 
export const ChartCurrentRobotLevelGrassCutting  = () => {
    const [chartData, setChartData] = useState({});
    const [data, setData] = useState([]);
    const areaCovered = [];
    const workingDate = [];
    const robotName = [];
    const GetCurrentRobotLevelGrassCutting = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //let response = await axios("/RobotLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
         let response = await axios("/RobotLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        setData(response.data);
        data.map((user) => {
            areaCovered.push(user.areaCovered);
            robotName.push(user.robotName + "-" + user.workingDate);
            workingDate.push(user.workingDate);
        });
        //*** Set Chart Data
        setChartData({
            labels: robotName,
            datasets: [
                {
                    label: "Area Covarage in Meter (m) By Current Date And Robots",
                    data: areaCovered, //[0, 10, 5, 2, 20, 30, 45],
                    backgroundColor: ['rgba(54, 162, 235, 0.2)'],
                    borderColor: ["rgba(255, 99, 132, 1)"], 
                    borderWidth: 1
                    
                 }
            ]
        });

    };
     //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        GetCurrentRobotLevelGrassCutting();
    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    GetCurrentRobotLevelGrassCutting();
    // *** Return html code 
    return (
     <>
       {hasJWTToken() ? (
        <div>
            <p></p>
            <h3 style={{ marginLeft: "2%" }}>Current Robot Level Grass Cutting</h3>
            <p></p>
            <p></p>
            {data.map((user) => (
         (user.id===0 ?(
            <div key={user.id}>
                <Bar data={chartData} />
            </div>
            ):(<></>))
            ))}
                </div>
            ) :
            (
               <Route to={{ pathname: '#/login' }} />
            )
       }
     </>
    );
};
//export default CurrentChartRobotLevelGrassCutting;
