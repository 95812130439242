// Plant.js
import React, { useState, useEffect } from 'react';
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/tab.css";
import "../../Assets/Styles/Form.css";
import {AddPlant} from "../Account/AddPlant";
import { SearchPlant } from "../Account/SearchPlant";
import { EditPlant } from "../Account/EditPlant";
import * as FaIcons from "react-icons/fa";
var _mode;
export const openTab = (pageName, elmnt, color, mode, pid) => {
    _mode = mode;
    sessionStorage.setItem("editpid", pid);
   // EditPlant(pid);
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
        tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < tablinks.length; i++) {
        tablinks[i].style.backgroundColor = "";
        //tablinks[i].style.color = "";
    }
    document.getElementById(pageName).style.display = "block";
    document.getElementById(elmnt).style.backgroundColor = color;
    window.location.href = "/EditPlant";
};
export function Plant () {
    const [plantMode, setPlantMode] = useState("Add");
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState();
    alert(_mode + ' plant');
    useEffect(() => {
        setPlantMode(plantMode);
        openTab('AddPlant', 'btnAddPlant', '#fff', "Add");
    }, [])
    useEffect(() => {
        console.log(plantMode)
    }, [plantMode])
   
    return (
        <div className="form-container1">
            <p></p>
            <button className="tablink" id="btnAddPlant" onClick={() => openTab('AddPlant', 'btnAddPlant', '#fff')}><FaIcons.FaFileMedical/> Add New Plant</button>
            <button className="tablink" id="btnSearchPlant" onClick={() => openTab('SearchPlant', 'btnSearchPlant', '#fff')}><FaIcons.FaSearch /> Search Plant</button>
            <button className="tablink" id="btnEditPlant" onClick={() => openTab('EditPlant', 'btnEditPlant', '#fff')}><FaIcons.FaRegEdit /> Edit Plant</button>
            <div id="AddPlant" className="tabcontent">
               <AddPlant></AddPlant>
            </div>
            <div id="SearchPlant" className="tabcontent">
                <SearchPlant></SearchPlant>
            </div>
            <div id="EditPlant" className="tabcontent">
            <EditPlant></EditPlant>
            </div>
        </div>    
    );
}
export default Plant;