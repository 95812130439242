//*** Import react library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import DataTable from "react-data-table-component";
//import table from "react-data-table-component";
//import Button from "react-bootstrap/Button";
import Axios from 'axios'
import mqtt from "mqtt/dist/mqtt";
import "../../Assets/Styles/robot.css";
import "../../Assets/Styles/tab.css";
import { RobotLocation } from "./RobotLocation";
//import { OlaMap } from "./OlaMap";
import Joystick from "./JoystickController";
import 'bootstrap/dist/css/bootstrap.css';
import { ChartCurrentRobotLevelGrassCutting } from "../Monitoring/ChartCurrentRobotLevelGrassCutting";
import { ChartCurrentRobotLevelSpraying } from "../Monitoring/ChartCurrentRobotLevelSpraying"; 
import { ChartCurrentPlantLevelGrassCutting } from "../Monitoring/ChartCurrentPlantLevelGrassCutting";
import { ChartCurrentPlantLevelSpraying } from "../Monitoring/ChartCurrentPlantLevelSpraying";
import Webcam from "react-webcam";
import Iframe from "react-iframe";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
//import ResponsiveEmbed from 'react-bootstrap/lib/ResponsiveEmbed';
// MQTT code
///***Use anyone MQTT Broker
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
//const url = 'ws://broker.hivemq.com:8000/mqtt'
const url = 'wss://broker.hivemq.com:8884/mqtt'

//*** Global variables ***//
var PlantID = sessionStorage.getItem("user_plantid");
const sruserID = sessionStorage.getItem("user_id");
const MQTT_Subscribe_Topic = "zylv4u8fun1";
const MQTT_Receiver_Topic = "e1ypuwron21";
const ClienID = Math.floor(100000000000 + Math.random() * 900000000000);
var SubscribtionStatus = false;
var BroadcastingStatus = false;
var CameraCaptureStatus = false;
var Current_command_status = null;
var Current_motor_speed_status = null;
var Current_turn_status = null;
var Current_grass_cutter_status = null;
var Current_sprayer_status = null;
var Current_sowing_status = null;
var Current_haversting_status = null;
var Current_data = null;
var current_lat = null;
var current_lon = null;
var IPAddress;
var RobotID; 

//Webcam variables
//const FACING_MODE_USER = "user";
//const FACING_MODE_ENVIRONMENT = "environment";
//*** End Global Variables

//*** Temp 31/01/24 Get pid from querystring
//let _plantID = (new URLSearchParams(window.location.search)).get("pid")
//PlantID = _plantID;

///***
// * for Support url -https://www.emqx.com/en/blog/mqtt-js-tutorial
// * Browser
// * Using MQTT over WebSocket with ws and wss protocols
// * EMQX's ws connection default port is 8083, wss is 8084
// * Note that you need to add a path after the connection address, such as /mqtt
// */
//const url = 'ws://broker.emqx.io:8083/mqtt'
///***
// * Node.js
// * Using MQTT over TCP with mqtt and mqtts protocols
// * EMQX's mqtt connection default port is 1883, mqtts is 8883
// */
//const url = 'mqtt://broker.emqx.io:1883'

//**** Set MQTT options parameters ***//
const options = {
   //clean session,
    clean: true,
    keepalive: 1060,
    connectTimeout: 500000,
    //*** Authentication
    clientId: `mqttjs_${Math.random().toString(16).substr(2, 8)}`, //can be changed for something more specific if you need it
    //userName: 'emqx_test',
    //password: 'emqx_test',
}

//*** Step-1 ***//
//*** Connect To MQTT broker ***//
//Temp comment on 20230718
var client = mqtt.connect(url, options)
client = new mqtt.connect(url, options)
client.on('connect', function (err) {
    if (err) {
       // alert("not connect");
        console.log("Not Connected To MQTT Broker");
    }
    if (!err) {
        //alert("connected");
        console.log("Connected To MQTT Broker");
    }
});

//*** If MQTT connection lost then continuously check broadcasting Network between Bhuhit UI and Bhuhit Robots ***/
//*** setInterval(check_mqtt_cloud_connectivity, 30000);
function check_mqtt_cloud_connectivity() {
    client.on('connect', function (err) {
        if (err) {
            check_auto_broadcast_all_robots();
        }
        if (!err) {
            console.log("Client Connected")
        }
    })
    // }
}

const check_auto_broadcast_all_robots = () => {
    // let tempList = List;
    // setList(List);
    // List.map((user) => (
   
    //tempList.map((user) => {
    //    alert("check all broadcast123");
    //    alert(user.robotID);
    //    alert(user.robotName);
    //    Create_broadcasting_command_message(user.robotID); // Send Robot ID for Broadcast
    //});
}

//*** Create broadcast frame message ***//
function Create_broadcasting_command_message(_robotID) {
    //*** Send Broadcast command to cloud database ***//
    //alert(PlantID);
    //alert(_robotID);
    const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + _robotID + "&rcs=Disconnected");
    //const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + _robotID + "&rcs=Disconnected");
    var obj = new Object();
    obj.FID = _robotID; //RobotID;
    var Broadcasting_command_message = JSON.stringify(obj);
    Send_broadcasting_command_message_to_robot(Broadcasting_command_message, _robotID);
}

//*** Step-3 ***//
/***Send MQTT broadcasting message to Robots ***/
function Send_broadcasting_command_message_to_robot(Broadcasting_command_msg, robotID) {
    try {
        client.subscribe(MQTT_Subscribe_Topic, function (err) {
            if (!err) {
                //*** Send MQTT Broadcasting Message to Robot ***//
                client.publish(MQTT_Subscribe_Topic, Broadcasting_command_msg)
                console.log("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
                //  alert("Step-1 (Broadcasting): Operator have sent Robot ID " + robotID + " to Robot and have completed step 1/6.");
                BroadcastingStatus = true;
            }
        })
    }
    catch (e) {
        console.log("Step-1 (Broadcasting) Error: " + e.message + "");
    }
}

//**** Receive command frame message from Robots to MQTT and then MQTT to Bhuhit UI****//
client.on('message', function (topic, message) {
    //alert(topic);
   //alert(topic + "," + message);
   //*** message is Buffer ***//
    var obj = JSON.parse(message);
    if (topic === MQTT_Receiver_Topic) {
         //setItemChecked(false);
        //alert(message);
        //*** Add robot connected status (status from robots) in cloud database ***//
        if (obj.FID != null && obj.IP != null) {
            IPAddress = obj.IP;
            RobotID = obj.FID;
           // alert(PlantID);
             const bcresponse = Axios("/RobotBroadcast/addbcs?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&rcs=Connected");
            //const bcresponse = Axios("/RobotBroadcast/addbcs?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&rcs=Connected");
        }
        
        //*** Check receving command message ***/
        if (obj.FID != null && obj.Cmd != null) {
            //*** Step-2 and Step-3 have executed. Operator have connected with Robots***//
            /*** Step-3 (Connectivity to Robot): You have connected to Robot and have completed step 3 /6. ***/
            console.log("Step-2 (Broadcasting): Operator have received Robot ID '" + obj.FID + "' from Robot and have completed step 2/6.");// message.payloading
            console.log("Step-3 (Connectivity to Robot): You are connected to Robot ID '" + obj.FID + "'  and have completed step 3 /6.");
            if (obj.Cmd != null) Current_command_status = obj.Cmd;
            if (obj.Spd != null) Current_motor_speed_status = obj.Spd;
            if (obj.Turn != null) Current_turn_status = obj.Turn;
            if (obj.GC != null) Current_grass_cutter_status = obj.GC;
            if (obj.SP != null) Current_sprayer_status = obj.SP;
            //if (obj.lat != null) Current_lat = obj.lat;
            //if (obj.lon != null) Current_lon = obj.lon;

            //**** Add command status (status from robots) in cloud database ****/
            if (obj.FID != null && obj.Cmd != null) {
                let rescmdstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&lat=0&lon=0&cb=0&mb=0&cid=1");
                  //let rescmdstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&lat=0&lon=0&cb=0&mb=0&cid=1");
                 //let RefreshPage = RefreshCommandStatus();
                //*** Updated code to test refresh receving commands on 20231111
            }

            if (obj.FID != null && obj.OPN != null) {
               // alert(obj.Cmd + " Operation Received1");
                let resoparationstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=OFF&tun=D&spd=0&gc=0&sp=0&lat=0&lon=0&cb=0&mb=0&cid=1");
                //let resoparationstatus = Axios("/PlantRobotOperation/addcmdstatus?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&cmd=OFF&tun=D&spd=0&gc=0&sp=0&lat=0&lon=0&cb=0&mb=0&cid=1");
               // let RefreshPage = RefreshCommandStatus(); //Updated code to test refresh receving commands on 20231111
            } 
        }

        //*** Update current lat and lon status from robots in cloud database ****/
        if (obj.FID != null && obj.lat != null && obj.lon != null) {
             //alert(obj.lat);
              let resplatlonstatus = Axios("/PlantRobotsLocation?pid=" + PlantID + "&uid=" + sruserID + "&rid=" + obj.FID + "&lat=" + obj.lat + "&lon=" + obj.lon + "&det=" + obj.Det + "");
             //let resplatlonstatus = Axios("/PlantRobotsLocation?pid=1&uid=" + sruserID + "&rid=" + obj.FID + "&lat=" + obj.lat + "&lon=" + obj.lon + "&det=" + obj.Det + "");
        }
        
        //console.log(message.toString() + " 1")
       //RefreshCommandStatus();
        //Temp comment 20231018 RefreshCommandStatus();
        //Temp comment 20231018 client.end();
    }
});

//**** To check error ****//
client.on('error', function (err) {
    alert(err.message);
});

const stop_operation = () => {
    //*** Send stop operation frame to robots ***//
    //*** Send_command_to_selected_robots("OFF")
    //*** Unsubscribe topic ***//
    client.unsubscribe("zylv4u8fun11", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic zylv4u8fun1')
            client.end()
            Set_Variables_Status();
        }
    })
    client.unsubscribe("e1ypuwron21", function (error) {
        if (error) {
            console.log(error)
        } else {
            console.log('Unsubscribed Toipic e1ypuwron21')
            client.end()
            Set_Variables_Status();
        }
    })

    //*** End clients ***//
    client.end()
    //*** Clear all global variables ***//
    Set_Variables_Status();
};

//*** Set global variables status null or false ***//
function Set_Variables_Status() {
    BroadcastingStatus = false;
    CameraCaptureStatus = false;
    Current_command_status = null;
    Current_turn_status = null;
    Current_motor_speed_status = null;
    Current_grass_cutter_status = null;
    Current_sprayer_status = null;
}

//*** Send MQTT frame message to robots ***//
function send_command_message_to_robot(command_msg, control, ctrl_current_value) {
    try {
        client.publish(MQTT_Subscribe_Topic, command_msg)
        console.log(command_msg)
    }
    catch (e) {
        console.log("Step-5 (Command Processing) Error: " + e.message + "");
    }
}
//*** Create array of columns of react data table ***//
//*** We are not using this code ***//
const columns = [
   /* { name: "Plant ID", selector: (row) => row.plantID, sortable: true },*/
    { name: "Block", selector: (row) => row.blockName, sortable: true },
    { name: "Row", selector: (row) => row.rowName, sortable: true },
    {
        name: "Robot", selector: (row) => row.robotName, sortable: true
       
        /* cell: (row) => <Link to={'robotopn/${row.robotName}'}>My Link</Link>*/
        /* cell: (row) => <Route path='/robotopn?rid=:{row.robotID}' ></Route>*/
        // cell: (row) => <a href="/robotopn/${row.robotID}">{row.robotName}</a>
    },
    { name: "GC", selector: (row) => row.grassCutter, sortable: true },
    { name: "SP", selector: (row) => row.sprayer, sortable: true },
    //{ name: "Sowing", selector: (row) => row.sowing, sortable: true },
    //{ name: "Harvesting", selector: (row) => row.harvesting, sortable: true },
    { name: "Command", selector: (row) => row.command, sortable: true },
    { name: "Status", selector: (row) => row.currentCommandStatus, sortable: true },
    //{ name: "Controller Battery", selector: (row) => row.controllerBatteryStatus, sortable: true },
    //{ name: "Motor Battery", selector: (row) => row.motorBatteryStatus, sortable: true },
    { name: "Connection", selector: (row) => row.broadcastStatus, sortable: true },
    { name: "Date and Time", selector: (row) => row.workingDate, sortable: true },
];
const customStyles = {
    table: {
        style: {
            border: "none",
            backgroundColor: "transparent",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "105%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "700",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            color: "#505050",
            fontWeight: "700",
            fontSize: "120%"
        },
    },
};

//*** Start code for multiple robot operation page ***//
export const RobotsOperation = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [List, setList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [commandID, setCommandID] = useState([]);
    const [masterChecked, setMasterChecked] = useState(false);
    const [itemChecked, setItemChecked] = useState(false);
    const srUserName = sessionStorage.getItem("username");
    const srPlantID = sessionStorage.getItem("user_plantid");
    const srToken = sessionStorage.getItem("token");
    const [userName, setUsername] = useState("");
    const [token, setToken] = useState("");
    const renderUserName = () => { setUsername(srUserName) };
    const rendertoken = () => { setToken(srToken) };
    const [pageRefreshStatus, setPageRefreshStatus] = useState(false);
    // For rear camera
    const webcamRef = React.useRef(null);
    const [image, setImage] = useState("");
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    const contextActions = (
        <button onClick={() => alert(data.map((r) => r.robotID))}>
            Show Selected Rows
        </button>
    );
    //setInterval(check_command_status, 120000);
    //function check_command_status() {
    //    RefreshCommandStatus();
    //    clearInterval();
    //}

    //Old code for Webcam videoConstraints
    //const videoConstraints = {
    //    width: { min: 480 },
    //    height: { min: 720 },
    //    facingMode: { exact: "environment" }
    //};
    
    //For Webcam new code
    //const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

    //const capture = React.useCallback(() => {
    //    const imageSrc = webcamRef.current.getScreenshot();
    //    setImage(imageSrc);
    //}, [webcamRef]);

    //let videoConstraints: MediaTrackConstraints = {
    //    facingMode: facingMode,
    //    width:480, //270,
    //   // height:720, //480
    //};

    //const handleClick = React.useCallback(() => {
    //    setFacingMode((prevState) =>
    //        prevState === FACING_MODE_USER
    //            ? FACING_MODE_ENVIRONMENT
    //            : FACING_MODE_USER
    //    );
    //}, []);

    //console.log(facingMode + videoConstraints);


    //*** Refresh command status ***//
    function  RefreshCommandStatus () {
        try {
             let response = Axios("/PlantRobotsOperation?pid="+ srPlantID +"&rt=multiple");
            //let response = await Axios("/PlantRobotsOperation?pid=1&rt=multiple");
            setList(response.data)
            setData(response.data)
        } catch (error) {
            console.log(error);
        }
    }
    
    //*** Select/Unselect table rows ***//
    const onMasterCheck = (e) => {
        let tempList = List;
        //*** Check/Uncheck all items
        tempList.map((user) => (user.selected = e.target.checked));
        //*** Update state
        setMasterChecked(e.target.checked);
        setList(tempList);
        setSelectedList(List.filter((e) => e.selected));
    }

    //*** Check table items ***//
    const onItemCheck = (e, item) => {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //*** Control master checkbox state
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        //*** Update state
        setMasterChecked(totalItems === totalCheckedItems);
        setList(tempList);
        setSelectedList(List.filter((e) => e.selected));
        setItemChecked(true);
        //this.setState({
        //    masterChecked: totalItems === totalCheckedItems,
        //    List: tempList,
        //    selectedList: List.filter((e) => e.selected),
        //});
    }

    //*** Subscribe to MQTT topic
    const Client_subscribe_to_mqtt_topic = () => {
        var subscribe_topic;
        try {
            //*** Subescribe to Pblish Topic 'zylv4u8fun' ***//
            client.subscribe(MQTT_Subscribe_Topic, function (err) {
                if (!err) {
                    console.log('Client Subscribed to MQTT Pblish Topic')
                }
                if (err) {
                    console.log('Client not Subscribed to MQTT Pblish Topic')
                }
            })
            subscribe_topic = MQTT_Subscribe_Topic;
            
            //*** Subscribe to message receiver topic ***//
            client.subscribe(MQTT_Receiver_Topic, function (err1) {
                if (!err1) {
                    console.log('Client Subscribed to MQTT receiver topic')
                }
                if (err1) {
                    console.log('Client not Subscribed to MQTT receiver topic')
                    client.subscribe(MQTT_Receiver_Topic);
                }
            })
            subscribe_topic = subscribe_topic + "," + MQTT_Subscribe_Topic;
            SubscribtionStatus = true;
        }
        catch (e) {
            SubscribtionStatus = false;
            console.log("Error to Subscribe Task: " + e.message + "");
        }
    }

    //*** Selected multiple robots for broadcast ***//
    const check_selected_robots_connected_status = () => {
        //document.getElementById(divcam).style.display = 'block';
        //*** Subscribe to MQTT topic ***//
        Client_subscribe_to_mqtt_topic();
        if (SubscribtionStatus === true) {
            //*** Get selected list in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                //*** Add and clear interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);
                Create_broadcasting_command_message(user.robotID); 
            });
        }
    }
   
    //*** Event to get selected rows(Optional)
    const send_command_to_selected_robots = (command, turn) => {
        //*** Add commands F ,B and S in session variable***// 
        if (command === "F" || command === "B" || command === "S") {
            sessionStorage.removeItem("Command");
            sessionStorage.setItem("Command", command);
        }
        //*** Create previous command and When command = "None" then command="S" ***//
        if (turn === "L" || turn === "R" || turn === "D") {
            if (command === "None" && sessionStorage.getItem("Command") === null) command = "S";
            if (command === "None" && (sessionStorage.getItem("Command") === "F" || sessionStorage.getItem("Command") == "B" || sessionStorage.getItem("Command") == "S")) command = sessionStorage.getItem("Command");
           // alert(command, turn + " from L and R "  );
        }
      
        //*** Subscribe to MQTT topic ***//
        Client_subscribe_to_mqtt_topic()
        //*** IF We subscribed to topic then we will send frame to robots ***//
        if (SubscribtionStatus === true) {
            //*** Get Selected List in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                //*** Set varibles to null
                Current_command_status = null;
                Current_motor_speed_status = null;
                Current_turn_status = null;
                Current_grass_cutter_status = null;
                Current_sprayer_status = null;

                //*** Add interval of 5 seconds to send frame for multiple robots and we will clear Interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);

                let grassCutterOn = document.getElementById(user.robotID + "-GCOn");
                let grassCutterOff = document.getElementById(user.robotID + "-GCOff");
                if (grassCutterOn.checked) {
                    Current_grass_cutter_status = 1;
                }
                else if (grassCutterOff.checked) {
                    Current_grass_cutter_status = 0;
                }
                let sprayerOn = document.getElementById(user.robotID + "-SPOn");
                let sprayerOff = document.getElementById(user.robotID + "-SPOff");
                if (sprayerOn.checked) {
                    Current_sprayer_status = 1;
                }
                else if (sprayerOff.checked) {
                    Current_sprayer_status = 0;
                }
                Current_command_status = command;
                Current_turn_status = turn;
                if (command === "S") { // when Command Stop then grass cutter and spraying status =0;
                    Current_grass_cutter_status=0;
                    Current_sprayer_status = 0;
                }
                if (Current_turn_status === null || Current_turn_status === '') Current_turn_status = 'D';
                if (Current_motor_speed_status === null || Current_motor_speed_status === '') Current_motor_speed_status = 255;
                if (Current_grass_cutter_status === null || Current_grass_cutter_status === '') Current_grass_cutter_status = 0;
                if (Current_sprayer_status === null || Current_sprayer_status === '') Current_sprayer_status = 0;

                //*** Send command to cloud database ***//
                //const response = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=1&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
                 const resResultAddCommandStatus = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=" + sruserID + "&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
                 //const resResultAddCommandStatus = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=" + sruserID + "&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");

                //* Get current Command ID *//
                //let resCommandID = Axios("/PlantRobotOperation/getcmdid?pid=" + user.plantID + "&rid=" + user.robotID + "");
                //setCommandID(resCommandID);
                //let datacid = commandID;
                //let command_id;
                //alert("v");
                //datacid.map((datalst) => {
                //    //alert(c.commandID);
                //    command_id = datalst.commandID
                //});
                //alert("v123");
                //alert(command_id);

                //*** Create Json object frame and send it to Robots ***//
                var obj = new Object();
                obj.FID = user.robotID;
                //*** IF command is OFF then we send frame to stop robot operation ***//
                if (command === "OFF") obj.OPN = command
               // alert(Current_command_status);
                //*** IF command is F/B/S then we send frame to robot operation  ***//
                if (command !== "OFF") {
                    obj.Cmd = command;
                    //obj.Cid = user.commandID;
                    obj.Turn = turn;
                    obj.Spd = 255;
                    obj.GC = Current_grass_cutter_status;
                    obj.SP = Current_sprayer_status;
                }
                var command_message = JSON.stringify(obj);
                try {
                    //*** Publish message using MQTT protocol;
                    client.publish(MQTT_Subscribe_Topic, command_message)
                    console.log(command_message)
                    //setItemChecked(false);
                    //fetchData();
                    //RefreshCommandStatus(); //Updated code to test refresh sending commands on 20231111
                }
                catch (e) {
                    console.log("Step-5 (Command Processing) Error: " + e.message + "");
                }
                //*** Update current command status ***//
                //const response1 = Axios("https://localhost:7248/PlantRobotsInfo?pid=1");
                //setData(response1.data)
            }
            );
           /* fetchData();*/
            //Temp comment on 20231018 RefreshCommandStatus();
        }

    };
    //*** hide camera ***//
    const hideCamera = (dvcam) => {
        document.getElementById(dvcam).style.display = 'none';
    }
    //*** show camera ***//
    const showCamera = (dvcam) => {
        document.getElementById(dvcam).style.display = 'block';
    }
    //*** Stop robots operation ***//
    const stop_selected_robots = () => {
        send_command_to_selected_robots("S", "D");
        //Temp comment on 20231018 RefreshCommandStatus();
    };
 
    //*** Get single robot operation page ***//
    const get_single_robot_operation = (robotID, plantID) => {
        sessionStorage.setItem("robotID", robotID);
        window.location.href = "/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
        //window.location.href = "http://bhuhit.com/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
    };

    //*** React hook to refresh page if any changes, page will auto refresh ***//
    
    useEffect(() => {
        setPageRefreshStatus(true);
        fetchData();

    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
   
    //*** Fetch data for multiple robot operation page ***//
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
       // alert(api.baseURL);
        //alert("hfgjgj");
        let response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        //const response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        setData(response.data);
        setList(response.data);
        setItemChecked(false);
      //  alert("123");
        //alert(pageRefreshStatus);
        /*if (pageRefreshStatus === true) {*/
            var baseURL = "/#/RobotsOperation?pid=" + plant_id + "";
            window.location.href = baseURL;
        //    setPageRefreshStatus(false);
        //}
        openPage('Operation', 'btnOperation', '#fff')
    }

  //  let tempList = RefreshCommandStatus();
       
    //*** Open tab ***//
    function openPage(pageName, elmnt, color) {
       // alert(pageName + ", " + elmnt + ", " + color);
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablink");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].style.backgroundColor = "";
            //tablinks[i].style.color = "";
        }
        document.getElementById(pageName).style.display = "block";
        document.getElementById(elmnt).style.backgroundColor = color;
    }

    function closeFrame() {
        //alert("ok");
        document.getElementById("outerdiv").style.display = "none";
    }
    return (
      <>
       {hasJWTToken() ? (
          <div>
            <p></p>
            <button className="tablink" id="btnOperation" onClick={() => openPage('Operation', 'btnOperation', '#fff')}>Operation</button>
            <button className="tablink" id="btnStatus" onClick={() => openPage('Status', 'btnStatus', '#fff')}>Status</button>
            <button className="tablink" onClick={() => openPage('Map', 'btnMap', '#fff')} id="btnMap">Map</button>
            <button className="tablink" id="btnChart" onClick={() => openPage('Chart', 'btnChart', '#fff')} >Chart</button>
              {/*<div> onClick={() => openPage('Chart', 'btnChart', '#fff')}</div>
               <div></div>*/}
            <div id="Operation" className="tabcontent">
                <h3 style={{ marginTop: "-2%", color: "#000" }}>Multiple Robot Operation</h3>
                {/*<Iframe url={IPAddress} width={1000} height={500} loading="lazy" allow="microphone;camera" ></Iframe>*/}
                {/* <Iframe url="https://www.youtube.com/embed/uXWycyeTeCs" width={1000} height={500} loading="lazy" allow="microphone;camera" ></Iframe>*/}
                {/*<div className="webcam-container">*/}
                {/*    <div className="webcam-img">*/}
                {/*        {image === "" ? (*/}
                {/*            <Webcam*/}
                {/*                className="webcam"*/}
                {/*                audio={false}*/}
                {/*                ref={webcamRef}*/}
                {/*                screenshotFormat="image/jpeg"*/}
                {/*                videoConstraints={videoConstraints}*/}
                {/*                screenshotQuality={1}*/}
                {/*            />*/}
                {/*        ) : (*/}
                {/*            <img*/}
                {/*                src={image}*/}
                {/*                alt="Scan"*/}
                {/*                style={{ width: "500px", height: "auto" }}*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*    <button  onClick={handleClick}>Switch Camera</button>*/}
                {/*</div>*/}
            <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    width:"99%",
                    backgroundColor: "#fff",
                }}
                >
                
                {srToken ? (
                    <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "91%" }} >
                        <div className="row dvscroll">
                            <div className="col-sm-12">
                                  <a id="btnRefreshCommandStatus" style={{ cursor: "Pointer" }} onClick={() => fetchData()}> <i class="fa fa-refresh" style={{ color: '#99cc33', fontSize: "40px" }}></i> </a>
                                <input
                                    type="text"
                                    placeholder="Search Results"
                                    style={{
                                        width: "20%",
                                        height: "30px",
                                        margin: "10px",
                                        float: "right",
                                        border: "none",
                                        padding: "1.5%",
                                        borderRadius: "5px",
                                    }}
                                    value={searchTerm}
                                    onChange={handleSearch}
                                />
                                <p></p>
                                <table className="table .table-sm table-hover" sort={true} pagination={true}
                                    search={true}
                                    data={filteredData}
                                    highlightOnHover={true}>
                                    <thead>
                                        <tr>
                                            <th scope="col">
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={masterChecked}
                                                    id="mastercheck"
                                                    onChange={(e) => onMasterCheck(e)}
                                                />
                                            </th>
                                            {/* <th scope="col" visible="false">Robot ID</th>*/}
                                            {/*<th scope="col">Block Name</th>*/}
                                            {/*<th scope="col">Row Name</th>*/}
                                            <th scope="col">Robot</th>
                                            <th scope="col">Camera</th>
                                           {/*<th scope="col">Speed</th>*/}
                                           {/*<th scope="col">Command</th>*/}
                                            <th scope="col">Status</th>
                                            {/*<th scope="col">Sowing</th>*/}
                                            {/*<th scope="col">Harvesting</th>*/}
                                            {/*<th scope="col">Command</th>*/}
                                            {/*<th scope="col">Command Status</th>*/}
                                            {/*<th scope="col">Command ID</th>*/}
                                            {/*<th scope="col">CB</th>*/}
                                            {/*<th scope="col">MB</th>*/}
                                            <th scope="col">Connection</th>
                                           {/* <th scope="col"> Date & Time</th>*/}
                                        </tr>
                                    </thead>
                                    <tbody>
                                            {List.map((user) => {
                                                return [
                                                    <tr key={user.id} className={user.selected ? "selected" :""}>
                                                        <th scope="row">
                                                            <input
                                                                  type="checkbox"
                                                                  checked={user.selected}
                                                                  className="form-check-input"
                                                                  id="rowcheck{user.id}"
                                                                  onChange={(e) => onItemCheck(e, user)}
                                                             />
                                                        </th>
                                                        {/*<td>{user.blockName}</td>*/}
                                                        {/*<td>{user.rowName}</td>*/}
                                                        <td className="tdcursorpointer" onClick={() => get_single_robot_operation(user.robotID, user.plantID)}><a>{user.robotName}</a>
                                                        </td>
                                                        <td>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <a style={{}} id={user.robotID + IPAddress} onClick={() => { check_selected_robots_connected_status(); showCamera('divcamera' + user.robotID); }} style={{ color: '#99cc33', fontSize: "15px", width: "100%", cursor: 'Pointer' }} ><i class="fa fa-cogs operation-icon" style={{ color: '#99cc33', fontSize: "20px", width: "100%" }} id="connect-icon"></i></a>
                                                                </div>
                                                                <div className="col">
                                                                    <a style={{ cursor: 'Pointer' }} id={user.robotID + IPAddress} onClick={() => { check_selected_robots_connected_status(); showCamera('divcamera' + user.robotID); }} ><i class="fa fa-video-camera" style={{ color: '#99cc33', fontSize: "20px", width: "100%" }}></i></a>
                                                                </div>
                                                            </div>
                                                            
                                                             {IPAddress && (RobotID === user.robotID) ? (
                                                                <div id={'divcamera' + user.robotID} className="dvcamera">
                                                                          <p>&nbsp;</p>
                                                                    <div className="row">
                                                                        {/*<div class="col-2"></div>*/}
                                                                        <div className="col">
                                                                            GC:&nbsp;
                                                                            {
                                                                                (itemChecked === false && (user.grassCutter === 'ON')) ? (
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={user.robotID + "-GCOn"}
                                                                                        name={user.robotID + "-GC"}
                                                                                        value="1"
                                                                                        checked="true"
                                                                                    />
                                                                                ) : (
                                                                                    <input
                                                                                        type="radio"
                                                                                        id={user.robotID + "-GCOn"}
                                                                                        name={user.robotID + "-GC"}
                                                                                        value="1"
                                                                                    />
                                                                                )
                                                                            }
                                                                            <span>&nbsp;On&nbsp;&nbsp;</span>
                                                                            {(itemChecked === false && (user.grassCutter === "OFF")) ? (
                                                                                <input
                                                                                    type="radio"
                                                                                    id={user.robotID + '-GCOff'}
                                                                                    name={user.robotID + "-GC"}
                                                                                    value="0"
                                                                                    checked="true"
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type="radio"
                                                                                    id={user.robotID + '-GCOff'}
                                                                                    name={user.robotID + "-GC"}
                                                                                    value="0"
                                                                                />
                                                                            )
                                                                            }

                                                                            <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                                        </div>
                                                                        {/*<div className="col">*/}
                                                                        {/*    */}{/*<Jostick/>*/}

                                                                        {/*</div>*/}
                                                                        <div className="col">
                                                                            SP:&nbsp;
                                                                            {(itemChecked === false && (user.sprayer === 'ON')) ? (
                                                                                <input style={{
                                                                                    color: '#99cc33'
                                                                                }}
                                                                                    type="radio"
                                                                                    id={user.robotID + "-SPOn"}
                                                                                    name={user.robotID + "-SP"}
                                                                                    value="1"
                                                                                    checked="true"
                                                                                />
                                                                            ) : (
                                                                                <input style={{
                                                                                    color: '#99cc33'
                                                                                }}
                                                                                    type="radio"
                                                                                    id={user.robotID + "-SPOn"}
                                                                                    name={user.robotID + "-SP"}
                                                                                    value="1"
                                                                                />
                                                                            )
                                                                            }
                                                                            <span>&nbsp;On&nbsp;&nbsp;</span>
                                                                            {(itemChecked === false && (user.sprayer === 'OFF')) ? (
                                                                                <input
                                                                                    type="radio"
                                                                                    id={user.robotID + "-SPOff"}
                                                                                    name={user.robotID + "-SP"}
                                                                                    value="0"
                                                                                    checked="true"
                                                                                />
                                                                            ) : (
                                                                                <input
                                                                                    type="radio"
                                                                                    id={user.robotID + "-SPOff"}
                                                                                    name={user.robotID + "-SP"}
                                                                                    value="0"
                                                                                />
                                                                            )
                                                                            }
                                                                            <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                                        </div>

                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnStart" onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Forward</a>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnReturn" onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Backward</a>*/}
                                                                        {/*</div>*/}
                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnLeft" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Left</a>*/}
                                                                        {/*</div>*/}


                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnTurnStop" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Stop Turn</a>*/}
                                                                        {/*</div>*/}

                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnRight" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Right</a>*/}
                                                                        {/*</div>*/}

                                                                        {/*<div className="col">*/}
                                                                        {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch" style={{ color: '#99cc33', fontSize: "27px", width: "100%" }}></i>Stop Robot</a>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                            <div className="row">
                                                                              
                                                                              <div className="col">
                                                                                <div id="Html1">
                                                                                <div id={'outerdiv'} className="container_1" style={{position:'relative'} }>
                                                                                <button type='button' className='hidecamera' onClick={() => hideCamera('divcamera' + user.robotID)} >X</button>
                                                                                            {/* <iframe src={"http://www.google.com.hk/webhp?hl=zh-CN&sourceid=cnhp"} id={'inneriframe'}></iframe>*/}
                                                                                <Iframe url={IPAddress} className='responsive-iframe_1' loading="lazy" allow="microphone;camera" id={'inneriframe'}></Iframe>
                                                                                    {/*<iframe src={IPAddress} width={1000} height={500} loading="lazy" allow="microphone;camera "></iframe>*/}

                                                                                    <div className="icon-forward">
                                                                                        <a id="btnStart" class="operation-icon" onTouchStart={() => send_command_to_selected_robots("F", "D")} onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-backward">
                                                                                        <a id="btnReturn" class="operation-icon" onTouchStart={() => send_command_to_selected_robots("B", "D")} onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-left">
                                                                                        <a id="btnLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left"></i></a>
                                                                                    </div>
                                                                                    <div className="icon-turn_stop">
                                                                                        <a id="btnTurnStop" class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-right">
                                                                                        <a id="btnRight" class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-forward-right">
                                                                                        <a id="btnForwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}><i class="fa fa-share"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-forward-left">
                                                                                        <a id="btnForwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "L")}><i class="fa fa-reply"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-backward-right">
                                                                                        <a id="btnBackwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}><i class="fa fa-reply fa-rotate-180"></i></a>
                                                                                    </div>

                                                                                    <div className="icon-backward-left">
                                                                                        <a id="btnBackwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "L")}><i class="fa fa-share fa-rotate-180"></i></a>
                                                                                    </div>
                                                                                    <div className="icon-stop">
                                                                                        <a id="btnRobotStop" class="operation-icon" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch" style={{ color: '#99cc33', fontSize: "35px", width: "100%" }}></i></a>
                                                                                    </div>
                                                                                   </div>
                                                                                </div>

                                                                            </div>
                                                                                {/*<div className="col">*/}
                                                                                {/*    <p>&nbsp;</p>*/}
                                                                                {/*    <div className="col">*/}
                                                                                {/*        <a id="btnForwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}><i class="fa fa-share"></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">FR</p>*/}
                                                                                {/*        <a id="btnTurnStop" class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o" style={{ color: '#99cc33', fontSize: "42px", width: "100%" }}></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">D</p>*/}
                                                                                {/*        <a id="btnBackwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}><i class="fa fa-reply fa-rotate-180"></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">BR</p>*/}
                                                                                {/*        <a id="btnRobotStop" class="operation-icon" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch" style={{ color: '#99cc33', fontSize: "42px", width: "100%" }}></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">S</p>*/}
                                                                                {/*    </div> */}
                                                                                {/*</div>*/}
                                                                                {/*<div className="col">*/}
                                                                                {/*    <p>&nbsp;</p>*/}
                                                                                {/*    <div className="col">*/}
                                                                                {/*       <a id="btnStart" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">F</p>*/}
                                                                                {/*       <a id="btnRight" class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right"></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">R</p>*/}
                                                                                {/*        <a id="btnReturn" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i></a>*/}
                                                                                {/*        <p class="operation-icon-text">B</p>*/}
                                                                                {/*    </div> */}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                            <p></p><p></p>
                                                                   
                                                                        </div>
                                                                    ) : (<></>)
                                                                    }
                                                            
                                                        </td>
                                                        {/* <td>{user.speed}</td>*/}
                                                        {/*<td>*/}
                                                        {/*    {user.command}   */}
                                                        {/*</td>*/}
                                                        <td>
                                                            {user.currentCommandStatus}
                                                        </td>
                                                        {/*<td>{user.command}</td>*/}
                                                        {/*<td>{user.currentCommandStatus}</td>*/}
                                                        {/*<td>{user.contromotorBatteryStatusllerBatteryStatus}</td>*/}
                                                        {/*<td>{user.motorBatteryStatus}</td>*/}

                                                        {user.broadcastStatus === 'Disconnected' ?
                                                            (
                                                                <td style={{ color: 'orange' }}><i class="fa fa-times-circle-o" style={{ fontSize: '25px', color: 'orange' }}></i></td>
                                                            ) :
                                                            (
                                                                <td style={{ color: '#99cc33' }}><i class="fa fa-check-circle-o" style={{ fontSize: '25px', color:'#99cc33'}}></i></td>
                                                            )}

                                                       {/* <td>{user.workingDate}</td>*/}

                                                    </tr>
                                      
                                                ];
                                            })}
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => check_selected_robots_connected_status()} ><i class="fa fa-cogs operation-icon" id="connect-icon"></i>Connect</a>
                                    </div>
                                    <div className="col">
                                        {/*<Button variant="primary"  style={{ backgroundColor: "#95c92c", marginBottom: "2%", marginLeft: "3%", fontWeight: "400", fontSize: "120%" }}>*/}
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnStart" onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up operation-icon" id="forward-icon"></i>Forward</a>
                                        {/*  </Button>*/}
                                    </div>
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnReturn" onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down operation-icon" id="backward-icon"></i>Backward</a>
                                    </div>
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnLeft" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left operation-icon" id="left-icon"></i>Left</a>
                                    </div>
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnTurnStop" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o operation-icon" id="turn-icon"></i>Stop Turn</a>
                                    </div>
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnRight" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right operation-icon" id="right-icon"></i>Right</a>
                                    </div>

                                    {/*<div className="col">*/}
                                    {/*     <a style={{ cursor: 'Pointer' }} id="btnForwardRight" onClick={() => send_command_to_selected_robots("F", "R")} ><i class="fa fa-caret-square-o-up" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i><i class="fa fa-caret-square-o-right" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i>Right</a>*/}
                                    {/*</div>*/}
                                    {/*<div className="col">*/}
                                    {/*      <a style={{ cursor: 'Pointer' }} id="btnForwardLeft" onClick={() => send_command_to_selected_robots("F", "L")} ><i class="fa fa-caret-square-o-up" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i><i class="fa fa-caret-square-o-left" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i>Left</a>*/}
                                    {/*</div>*/}
                                    {/*<div className="col">*/}
                                    {/*      <a style={{ cursor: 'Pointer' }} id="btnBackwardRight" onClick={() => send_command_to_selected_robots("B", "R")} ><i class="fa fa-caret-square-o-down" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i><i class="fa fa-caret-square-o-right" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i>Right</a>*/}
                                    {/*</div>*/}
                                    {/*<div className="col">*/}
                                    {/*      <a style={{ cursor: 'Pointer' }} id="btnBackwardLeft" onClick={() => send_command_to_selected_robots("B", "L")} ><i class="fa fa-caret-square-o-down" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i><i class="fa fa-caret-square-o-left" style={{ color: '#99cc33', fontSize: "25px", width: "100%" }}></i>Left</a>*/}
                                    {/*</div>*/}

                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch operation-icon" id="stop-icon"></i>Stop Robot</a>
                                    </div>
                                    <div className="col">
                                            <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => send_command_to_selected_robots("OFF")}><i class="fa fa-power-off operation-icon" id="off-icon"></i>Stop</a>
                                    </div>

                                </div>
                                </div>
                        </div>
                    </div>
                ) : (<></>)}
                </div>
            </div>
            <div id="Status" className="tabcontent">
                <h3 style={{ marginTop: "-2%", color: "#000" }}>Monitoring Robotic Operation Status</h3>
                {/*<* src="https://www.youtube.com/embed/uXWycyeTeCs" width={1000} height={500} loading="lazy"></iframe>;*/}
                {/*<Iframe url={IPAddress} width={1000} height={500} loading="lazy" allow="microphone;camera" ></Iframe>*/}
                {/* <Iframe url="https://www.youtube.com/embed/uXWycyeTeCs" width={1000} height={500} loading="lazy" allow="microphone;camera" ></Iframe>*/}
                {/*<div className="webcam-container">*/}
                {/*    <div className="webcam-img">*/}
                {/*        {image === "" ? (*/}
                {/*            <Webcam*/}
                {/*                className="webcam"*/}
                {/*                audio={false}*/}
                {/*                ref={webcamRef}*/}
                {/*                screenshotFormat="image/jpeg"*/}
                {/*                videoConstraints={videoConstraints}*/}
                {/*                screenshotQuality={1}*/}
                {/*            />*/}
                {/*        ) : (*/}
                {/*            <img*/}
                {/*                src={image}*/}
                {/*                alt="Scan"*/}
                {/*                style={{ width: "500px", height: "auto" }}*/}
                {/*            />*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*    <button  onClick={handleClick}>Switch Camera</button>*/}
                {/*</div>*/}
                <div
                    style={{
                        border: "1px solid #c0c0c0",
                        borderRadius: "5px",
                        width: "99%",
                        backgroundColor: "#fff",
                    }}
                >
                    {srToken ? (
                        <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "91%" }} >
                            <div className="row dvscroll">
                                <div className="col-sm-12">
                                   {/* <a id="btnRefreshCommandStatus" style={{ cursor: "Pointer" }} onClick={() => fetchData()}> <i class="fa fa-refresh" style={{ color: '#99cc33', fontSize: "40px" }}></i> </a>*/}
                                    <input
                                        type="text"
                                        placeholder="Search Results"
                                        style={{
                                            width: "20%",
                                            height: "30px",
                                            margin: "10px",
                                            float: "right",
                                            border: "none",
                                            padding: "1.5%",
                                            borderRadius: "5px",
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <p></p>
                                    <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                        search={true}
                                        columns={columns}
                                        data={filteredData}
                                        highlightOnHover={true}
                                        customStyles={customStyles}>
                                        <thead>
                                            <tr>
                                                {/* <th scope="col" visible="false">Robot ID</th>*/}
                                                <th scope="col">Block Name</th>
                                                <th scope="col">Row Name</th>
                                                <th scope="col">Robot Name</th>
                                                <th scope="col">Speed</th>
                                                <th scope="col">Grass Cutter</th>
                                                <th scope="col">Sprayer</th>
                                                {/*<th scope="col">Sowing</th>*/}
                                                {/*<th scope="col">Harvesting</th>*/}
                                                <th scope="col">Command ID</th>
                                                <th scope="col">Command</th>
                                                <th scope="col">Command Status</th>
                                                {/*<th scope="col">Command ID</th>*/}
                                               {/*<th scope="col">CB</th>*/}
                                                {/*<th scope="col">MB</th>*/}
                                                <th scope="col">Connection</th>
                                                <th scope="col"> Date & Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {List.map((user) => {
                                                return [
                                                    <tr key={user.id} className={user.selected ? "selected" : ""}>

                                                        <td>{user.blockName}</td>
                                                        <td>{user.rowName}</td>
                                                        <td>{user.robotName}</td>
                                                        <td>{user.speed}</td>
                                                        <td>{user.grassCutter}</td>
                                                        <td>{user.sprayer}</td>
                                                        <td>{user.commandID}</td>
                                                        <td>{user.command}</td>
                                                        <td>{user.currentCommandStatus}</td>
                                                        {/*<td>{user.contromotorBatteryStatusllerBatteryStatus}</td>*/}
                                                        {/*<td>{user.motorBatteryStatus}</td>*/}
                                                        {user.broadcastStatus === 'Disconnected' ?
                                                            (
                                                                <td style={{ color: 'orange' }}><i class="fa fa-times-circle-o" style={{ fontSize: '25px', color: 'orange' }}></i></td>
                                                            ) :
                                                            (
                                                                <td style={{ color: '#99cc33' }}><i class="fa fa-check-circle-o" style={{ fontSize: '25px', color: '#99cc33' }}></i></td>
                                                            )}
                                                        <td>{user.workingDate}</td>

                                                    </tr>,
                                                ];
                                            })}
                                        </tbody>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    ) : (<></>)}
                </div>
            </div>
            <div id="Map" className="tabcontent">
                <div style={{ marginTop: "-2%" }}>
                    <RobotLocation></RobotLocation>
                    {/*<OlaMap></OlaMap>*/}
                </div>
            </div>
            <div id="Chart" className="tabcontent">
                <div style={{ marginTop: "-2%" }}>
                    <div className="row" style={{ width: "100%" }}>
                        <div className="column chart">
                            <ChartCurrentRobotLevelGrassCutting></ChartCurrentRobotLevelGrassCutting>
                        </div>
                        <div className="column" style={{ width: "4%" }}></div>
                        <div className="column chart">
                            <ChartCurrentRobotLevelSpraying></ChartCurrentRobotLevelSpraying>
                        </div>
                    </div>
                    <p></p>
                    <div className="row" style={{ width: "100%" }}>
                        <div className="column chart">
                            <ChartCurrentPlantLevelGrassCutting></ChartCurrentPlantLevelGrassCutting>
                        </div>
                        <div className="column" style={{ width: "4%" }}></div>
                        <div className="column chart">
                            <ChartCurrentPlantLevelSpraying></ChartCurrentPlantLevelSpraying>
                        </div>
                    </div>
                </div>
                </div>
          </div>
       ) :
       (
            <Route to={{ pathname: '#/login' }} />
       )
      }

      </>
    );
};

               
