// Plant.js
import React, { useState, useEffect } from 'react';
import "../../Assets/Styles/Form.css";
import Axios from 'axios';
import * as FaIcons from "react-icons/fa";
import { hasJWTToken } from "../Account/RouteGuard";
export function FetchPlantId(pid) {
    //const [plantID, setPlantID] = useState("");
   // setPlantID(pid);
}

export function EditPlant() {
    const [plantData, setPlantData] = useState([]);
    const [plantList, setPlantList] = useState([]);
    const [editPlantID, setEditPlantID] = useState("");
    const _userID = sessionStorage.getItem("user_id");
    let _token = sessionStorage.getItem("token");
    if (_token) _token = "Token exists";
    let [responseData, setResponseData] = useState();
    let [formData, setFormData] = useState({
        company_id: '',
        plant_name: '',
        contact_person_name: '',
        contact_number: '',
        contact_email_id: '',
        plant_address: '',
        plant_lat: '',
        plant_lon: '',
        plant_time_zone_diff: '',
        critical_battery_level: '',
        plant_added_date: '',
        company_name: '',
        plant_id: '0',
        user_id: _userID,
        jwt_token: _token,
        plant_mode: "Update",
        message: '',
    });
    
    const [errors, setErrors] = useState({});
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        //alert("g");
        const newErrors = validateForm(formData);
      //  alert("g1");
        setErrors(newErrors);
        //alert(formData.companyID);
        //let response = Axios.post("/Plant", formData)
        //fetch('https://localhost:7248/Plant', {
        //    method: 'POST',
        //    headers: { 'Content-type': 'application/json', 'Accept': 'application/json' },
        //    body: formData
        //}).then(r => r.json()).then(res => {
        //    if (res) {
        //        this.setState({ message: 'New Employee is Created Successfully'});
        //    }
        //});
        //alert("gh1");
      //  let plant_response = Axios.post("/Plant", formData)
        if (Object.keys(newErrors).length === 0) {
            // Form submission logic here
            let objPlant = Axios.get("/Plant", formData)
            alert(objPlant.data);
            setResponseData(objPlant.data);
            objPlant.map((user) => {
                if (user.message) {
                    //*** Invalid User Name
                    //setErrorMessages({ name: "invalid", message: "Invalid user name." });
                   // return;
                    alert(user.message);
                    formData.message = user.message;
                }
            }
           )
            //if (objPlant.data) {
            //    //alert("ok");
            //    formData.message = formData.message;
            //}
            //'Plant submitted successfully!'
            //setFormData(formData);
           // formData.message = "Added new plant successfully!";
            
            console.log('Form submitted successfully!');
        } else {
            formData.message = 'Plant submission failed due to validation errors.'
            console.log('Form submission failed due to validation errors.');
        }
    };

    const validateForm = (data) => {
        const errors = {};
        if (!data.company_id.trim()) {
            errors.company_id = 'Company ID is required';
        } else if (data.company_id.length > 10) {
            errors.company_id = 'Company ID should not be less than or more than 10 digits';
        }

        if (!data.plant_name.trim()) {
            errors.plant_name = 'Plant name is required';
        } else if (data.plant_name.length > 50) {
            errors.plant_name = 'Plant name should not be less than or more than 50 characters';
        }

        if (!data.contact_person_name.trim()) {
            errors.contact_person_name = 'Contact person is required';
        } else if (data.contact_person_name.length > 50) {
            errors.contact_person_name = 'Contact Person should not be less than or more than 50 characters';
        }

        if (!data.contact_number.trim()) {
            errors.contact_number = 'Contact number is required';
        } else if (data.contact_number.length > 35) {
            errors.contact_number = 'Contact number should not be less than or more than 35 characters';
        }

        if (!data.contact_email_id.trim()) {
            errors.contact_email_id = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.contact_email_id)) {
            errors.contact_email_id = 'Email is invalid';
        }

        if (!data.plant_address.trim()) {
            errors.plant_address = 'Plant address is required';
        } else if (data.plant_address.length > 100) {
            errors.plant_address = 'Plant address should not be less than or more than 100 characters';
        }

        if (!data.plant_lat.trim()) {
            errors.plant_lat = 'Plant latitude is required';
        } else if (data.plant_lat.length > 15) {
            errors.plant_lat = 'Plant lat should not be less than or more than 15 characters';
        }

        if (!data.plant_lon.trim()) {
            errors.plant_lon = 'Plant longitude is required';
        } else if (data.plant_lon.length > 15) {
            errors.plant_lon = 'Plant lon should not be less than or more than 15 characters';
        }

        if (!data.plant_time_zone_diff.trim()) {
            errors.plant_time_zone_diff = 'Plant time zone difference is required';
        } else if (data.plant_time_zone_diff.length > 5) {
            errors.plant_time_zone_diff = 'Time zone difference should not be less than or more than 5 digits';
        }

        if (!data.critical_battery_level.trim()) {
            errors.critical_battery_level = 'Plant critical battery level is required';
        } else if (data.critical_battery_level.length > 3) {
            errors.critical_battery_level = 'Critical battery level should not be less than or more than 3 digits';
        }
        return errors;
    };
       
    //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        let _plant_id = false;
        _plant_id = sessionStorage.getItem("editpid");
       //alert( _plant_id)
        setTimeout(() => {
            getPlantData();
            setFormData(formData);
        }, 1000);
        if (_plant_id) {
       // setFormData(formData)
        //getPlantData();
          //setFormData(formData)
           alert("useEffect");
           //setFormData(formData);
        }
    }, [])
    useEffect(() => {
       // console.log(pid)
    }, [formData]);
   
    //if (pid) {
    //    alert(pid);
    //    alert("fffffffffffffffff");
    //}
   // getPlantData(pid);
    //*** Fetch data from API // To Search 94fbr
    const getPlantData = async () => {
       // alert("getPlantData");
        var plant_id = sessionStorage.getItem("editpid");//sessionStorage.getItem("user_plantid");
       // alert(_pid);
        //alert("fgh");
        var user_id = sessionStorage.getItem("user_id");
        var _token = sessionStorage.getItem("token");
        if (_token) _token = "Token exists";
        alert(plant_id);
        alert(user_id);
        let response = await Axios("/Plant?pid=" + plant_id + "&uid=" + user_id + "&pn=GetPlantByPlantId");
        setPlantData(response.data)
        setPlantList(response.data)
        //setFormData(plantList)
        let tempformData = plantList
        tempformData.map((plantData1) => {
            if (plantData1.company_id) {
                alert("temp");
                formData.company_id = plantData1.company_id;
                formData.plant_name = plantData1.plant_name;
                formData.contact_person_name = plantData1.contact_person_name;
                formData.contact_number = plantData1.contact_number;
                formData.contact_email_id = plantData1.contact_email_id;
                formData.plant_name = plantData1.plant_name;
                formData.plant_address = plantData1.plant_address;
                formData.plant_lat = plantData1.plant_lat;
                formData.plant_lon = plantData1.plant_lon;
                formData.plant_time_zone_diff = plantData1.plant_time_zone_diff;
                formData.critical_battery_level = plantData1.critical_battery_level;
                formData.plant_lon = plantData1.plant_lon;
               // setFormData(formData)
            }
            setFormData(formData);
            //return plantData1;
            
        });
    }
    
    return (
            <div className="form-container">
            <h2 className="form-title"><FaIcons.FaRegEdit/> Edit Plant</h2>
            <span className="form-message">
                {formData.message}
            </span>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        className="form-input"
                        type="number"
                        maxLength="10"
                        name="plant_id"
                        value={formData.plant_id}
                        onChange={handleChange}
                        //onBlur={getPlantData(formData.plant_id)}
                    />
                    <input
                        className="form-input"
                        type="number"
                        maxLength="10"
                        name="company_id"
                        value={formData.company_id}
                        onChange={handleChange}
                        placeholder="Enter company ID (Max limit 10 digits)"
                    />
                    {errors.company_id && (
                        <span className="error-message">
                            {errors.company_id}
                        </span>
                    )}
                </div>

                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_name"
                        maxLength="50"
                        value={formData.plant_name}
                        onChange={handleChange}
                        placeholder="Enter plant name (Max limit 50 char)"
                    />
                    {errors.plant_name && (
                        <span className="error-message">
                            {errors.plant_name}
                        </span>
                    )}
                </div>
                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="contact_person_name"
                        value={formData.contact_person_name}
                        maxLength="50"
                        onChange={handleChange}
                        placeholder="Enter contact person (Max limit 50 char)"
                    />
                    {errors.contact_person_name && (
                        <span className="error-message">
                            {errors.contact_person_name}
                        </span>
                    )}
                </div>

                <div>

                    <input
                        className="form-input"
                        type="text"
                        name="contact_number"
                        value={formData.contact_number}
                        onChange={handleChange}
                        maxLength="35"
                        placeholder="Enter contact number (Max limit 35 char)"
                    />
                    {errors.contact_number && (
                        <span className="error-message">
                            {errors.contact_number}
                        </span>
                    )}
                </div>
                <div>

                    <input
                        className="form-input"
                        type="text"
                        name="contact_email_id"
                        value={formData.contact_email_id}
                        onChange={handleChange}
                        maxLength="30"
                        placeholder="Enter contact email address (Max limit 30 char)"
                    />
                    {errors.contact_email_id && (
                        <span className="error-message">
                            {errors.contact_email_id}
                        </span>
                    )}
                </div>

                <div>

                    <input
                        className="form-input"
                        type="text"
                        name="plant_address"
                        value={formData.plant_address}
                        onChange={handleChange}
                        maxLength="100"
                        placeholder="Enter plant address (Max limit 100 char)"
                    />
                    {errors.plant_address && (
                        <span className="error-message">
                            {errors.plant_address}
                        </span>
                    )}
                </div>

                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_lat"
                        value={formData.plant_lat}
                        maxLength="15"
                        onChange={handleChange}
                        placeholder="Enter plant latitude (Max limit 15 char)"
                    />
                    {errors.plant_lat && (
                        <span className="error-message">
                            {errors.plant_lat}
                        </span>
                    )}
                </div>

                <div>
                    <input
                        className="form-input"
                        type="text"
                        name="plant_lon"
                        value={formData.plant_lon}
                        maxLength="15"
                        onChange={handleChange}
                        placeholder="Enter plant longitude (Max limit 15 char)"
                    />
                    {errors.plant_lon && (
                        <span className="error-message">
                            {errors.plant_lon}
                        </span>
                    )}
                </div>

                <div>
                    <input
                        className="form-input"
                        type="number"
                        name="plant_time_zone_diff"
                        maxLength="5"
                        value={formData.plant_time_zone_diff}
                        onChange={handleChange}
                        placeholder="Enter plant time zone difference (Max limit 5 digits)"
                    />
                    {errors.plant_time_zone_diff && (
                        <span className="error-message">
                            {errors.plant_time_zone_diff}
                        </span>
                    )}
                </div>

                <div>
                    <input
                        className="form-input"
                        type="number"
                        name="critical_battery_level"
                        value={formData.critical_battery_level}
                        maxLength="3"
                        onChange={handleChange}
                        placeholder="Enter critical_battery_level (Max limit 3 digits)"
                    />
                    {errors.critical_battery_level && (
                        <span className="error-message">
                            {errors.critical_battery_level}
                        </span>
                    )}
                </div>
                <button className="submit-button" type="submit">Submit</button>
            </form>
        </div>
    );
}

export default EditPlant;