//*** Import React library 
import axios from "axios";
import { Button } from "bootstrap";
import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";

//*** Current Grass Cutting and Spraying  components
export const ChartCurrentGrassCuttingSpraying = () => {
    const [chartData, setChartData] = useState({});
    const [data, setData] = useState([]);
    const areaCovered = [];
    const workingDate = [];
    const robotName = [];

    //*** Fetch data from API
    const GetCurrentGrassCuttingSpraying = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //let response = await axios("/PlantLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        let response = await axios("/PlantLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        setData(response.data);
        data.map((user) => {
            areaCovered.push(user.areaCovered);
            //robotName.push(user.robotName + "-" + user.workingDate);
            workingDate.push(user.workingDate);
            //robotName.push(user.robotName);
        });
       //*** Set Chart Data
        setChartData({
            labels: ["Grass Cutting","Spraying"],
            datasets: [
                {
                    label: "Area Covarage in Meter (m) By Current Date",
                    data: [30,40], //[0, 10, 5, 2, 20, 30, 45],
                    // backgroundColor: ["rgba(255, 99, 132, 0.2)"],
                    // borderColor: ["rgba(255, 99, 132, 1)"],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                    ],
                    borderWidth: 1,
                    width:"30px"
                }
            ]
        });

    };
     //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        GetCurrentGrassCuttingSpraying();
    }, []);
    useEffect(() => {
        console.log(data)
    }, [data])
    GetCurrentGrassCuttingSpraying();
    //*** Return html code 
    return (
         <>
       {hasJWTToken() ? (
        <div className="App1">
            <p></p>
            <h3 style={{ marginLeft: "2%" }}>Current Grass Cutting And Spraying</h3>
            <p></p>
            <p></p>
            {data.map((user) => (
                (user.id === 0 ? (
                    <div key={user.id}>
                        <Doughnut data={chartData} />
                    </div>
                ) : (<></>))
            ))}
                </div>
            ) :
                (
                    <Route to={{ pathname: '#/login' }} />
                )
            }
        </>
    );
};