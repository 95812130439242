//*** Import React Library and components
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Table from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Axios from 'axios'
//import { Bar } from 'react-chartjs-2';
import "../../Assets/Styles/robot.css";
import * as hdf5 from 'jsfive';
import { ChartCurrentRobotLevelGrassCutting } from "./ChartCurrentRobotLevelGrassCutting";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";

//*** Create react table
const columns = [
    { name: "Sr. No.", selector: (row) => row.id + 1, sortable: true },
    { name: "Robot Name", selector: (row) => row.robotName, sortable: true },
    { name: "Working Date", selector: (row) => row.workingDate, sortable: true },
    { name: "Area Covered (Cycles)", selector: (row) => row.areaCovered, sortable: true },
];
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};
export const CurrentRobotLevelGrassCutting = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([])
    const [List, setList] = useState([])
    const [SelectedList, setSelectedList] = useState([])
    const [MasterChecked, setMasterChecked] = useState(false)
    const [robotName, setRobotName] = useState([])
    const [cycles, setCycles] = useState([])
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    //const contextActions = (
    //    <button onClick={() => alert(data.map((r) => r.robotID))}>
    //        Show Selected Rows
    //    </button>
    //);
    useEffect(() => {
        fetchData();
       // fetch("https://n5eil02u.ecs.nsidc.org/egi/request?short_name=SPL2SMP_NRT&version=107&format=CSV&time=2023-12-28&bounding_box=-109,37,-102,41&bbox=-109,37,-102,41&Coverage=/BRIGHTNESS TEMPERATURE/SURFACE SOIL MOISTURE")
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
    //fetch(file_url)
    //    .then(function (response) {
    //        return response.arrayBuffer()
    //    })
    //    .then(function (buffer) {
    //        var f = new hdf5.File(buffer, filename);
    //        // do something with f;
    //        // let g = f.get('group');
    //        // let d = f.get('group/dataset');
    //        // let v = d.value;
    //        // let a = d.attrs;
    //    });
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
         const response = await Axios("/RobotLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        //const response = await Axios("/RobotLevelCurrentGrassCuttingStatus?pid=" + plant_id + "");
        setData(response.data)
        setList(response.data)
     
    }
    return (
        <>
          {hasJWTToken() ? (
            <div>
                <h3 style={{ margin: "2%", marginLeft: "2%" }}>Current Robot Level Grass Cutting</h3>
                <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    margin: "2%",
                    backgroundColor: "#fff",
                }}
              >
                <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "92%" }} >
                    <div className="row dvscroll">
                        <div className="col-sm-6">
                            <input
                                type="text"
                                placeholder="Search Results"
                                style={{
                                    width: "20%",
                                    height: "30px",
                                    margin: "10px",
                                    float: "right",
                                    border: "none",
                                    padding: "1.5%",
                                    borderRadius: "5px",
                                }}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <p></p>
                            <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                search={true}
                                columns={columns}
                                data={filteredData}
                                highlightOnHover={true}
                                customStyles={customStyles}
                            >
                            </DataTable>
                        </div>
                        <div className="col-sm-6">
                            <ChartCurrentRobotLevelGrassCutting></ChartCurrentRobotLevelGrassCutting>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            ) :
                (
                    <Route to={{ pathname: '#/login' }} />
                )
            }
        </>
    );
};
