//*** Import react library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom/client";
import DataTable from "react-data-table-component";
//import table from "react-data-table-component";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Axios from 'axios'
import mqtt from "mqtt/dist/mqtt";
import "../../Assets/Styles/robot.css";
import "../../Assets/Styles/tab.css";
import "../../Assets/Styles/ToggleSwitch.css";
import { RobotLocation } from "./RobotLocation";
import Joystick from "./JoystickController";
import 'bootstrap/dist/css/bootstrap.css';
import Webcam from "react-webcam";
import Iframe from "react-iframe";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
import { ToggleSwitch }  from "../RobotOperation/ToggleSwitch";
import Multiselect from 'multiselect-react-dropdown';
import bg from "../../Assets/Images/robot_bg3.jpg";
import robot_operation from "../../Assets/Images/robot_operation.jpeg";
import { MQTT } from "../RobotOperation/MQTT";
import { Client_subscribe_to_mqtt_topic } from "../RobotOperation/MQTT";
import { Create_broadcasting_command_message } from "../RobotOperation/MQTT";
import { send_command_message_to_robot } from "../RobotOperation/MQTT";
import { CaptureCamera } from "../RobotOperation/MQTT";
import Webcam1  from "./Webcam";


//import { PowerLight } from "../RobotOperation/Light";
//import { Light } from "../RobotOperation/Light";
import "../../Assets/Styles/PowerLamp.css";
//import ResponsiveEmbed from 'react-bootstrap/lib/ResponsiveEmbed';
// MQTT code
///***Use anyone MQTT Broker
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
//const url = 'ws://broker.hivemq.com:8000/mqtt'

//Following Main url which is working on local
//import ResponsiveEmbed from 'react-bootstrap/lib/ResponsiveEmbed';
// MQTT code
///***Use anyone MQTT Broker
//const url = 'ws://broker.emqx.io:8083/mqtt'
//const url = 'ws://broker.mqttdashboard.com:8000/mqtt'
//const url = 'ws://broker.hivemq.com:8000/mqtt'
const url = 'wss://broker.hivemq.com:8884/mqtt'

//*** Global variables ***//
var PlantID = sessionStorage.getItem("user_plantid");
const sruserID = sessionStorage.getItem("user_id");
const MQTT_Subscribe_Topic = "zylv4u8fun1";
const MQTT_Receiver_Topic = "e1ypuwron21";
const ClienID = Math.floor(100000000000 + Math.random() * 900000000000);
var SubscribtionStatus = false;
var BroadcastingStatus = false;
var CameraCaptureStatus = false;
var Current_command_status = null;
var Current_motor_speed_status = null;
var Current_turn_status = null;
var Current_grass_cutter_status = null;
var Current_sprayer_status = null;
var Current_sowing_status = null;
var Current_haversting_status = null;
var Current_data = null;
var current_lat = null;
var current_lon = null;
var IPAddress= "http://192.168.0.108:8080/";
var RobotID;

//Webcam variables
//const FACING_MODE_USER = "user";
//const FACING_MODE_ENVIRONMENT = "environment";
//*** End Global Variables


///***
// * for Support url -https://www.emqx.com/en/blog/mqtt-js-tutorial
// * Browser
// * Using MQTT over WebSocket with ws and wss protocols
// * EMQX's ws connection default port is 8083, wss is 8084
// * Note that you need to add a path after the connection address, such as /mqtt
// */
//const url = 'ws://broker.emqx.io:8083/mqtt'
///***
// * Node.js
// * Using MQTT over TCP with mqtt and mqtts protocols
// * EMQX's mqtt connection default port is 1883, mqtts is 8883
// */
//const url = 'mqtt://broker.emqx.io:1883'

//*** Create array of columns of react data table ***//

//*** Start code for multiple robot operation page ***//
export const RobotOperation = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([]);
    const [List, setList] = useState([]);
    const [selectedList, setSelectedList] = useState([]);
    const [commandID, setCommandID] = useState([]);
    const [masterChecked, setMasterChecked] = useState(false);
    const [itemChecked, setItemChecked] = useState(false);
    const srUserName = sessionStorage.getItem("username");
    const srPlantID = sessionStorage.getItem("user_plantid");
    const srToken = sessionStorage.getItem("token");
    const [userName, setUsername] = useState("");
    const [token, setToken] = useState("");
    const renderUserName = () => { setUsername(srUserName) };
    const rendertoken = () => { setToken(srToken) };
    const [pageRefreshStatus, setPageRefreshStatus] = useState(false);
    const [getRobotList, setGetRobotList] = useState([]);
    // For rear camera
    const webcamRef = React.useRef(null);
    const [image, setImage] = useState("");
    const [state, setState] = React.useState(3);
    const Light = ({ active, color }) =>
        <div class="Light" style={{ background: active ? color : "gray" }} />
    const PowerLight = ({ state }) =>
        <div class="PowerLight">
            <Light active={state === 0} color="orange" />
            <Light active={state === 1} color="red" />
            <Light active={state === 2} color="lime" />
        </div>;

    function GetRefershPage() {
        alert("hfhhfd");
        RefreshCommandStatus();
    };
    
    const onSelect= (selectedList, selectedItem) => {
       // alert(selectedItem.value);
       // alert(selectedItem.List);
    };

    const onRemove = (selectedList, removedItem) => {
        //alert(selectedList);
       // alert(removedItem);
    };

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );
    const handleRowSelected = (rows) => {
        setData(rows.selectedRows);
    };
    const contextActions = (
        <button onClick={() => alert(data.map((r) => r.robotID))}>
            Show Selected Rows
        </button>
    );
    //*** Refresh command status ***//
    function RefreshCommandStatus () {
        try {
           // alert("1");
            let response = Axios("/PlantRobotsOperation?pid=" + srPlantID + "&rt=multiple");
            //let response = await Axios("/PlantRobotsOperation?pid=1&rt=multiple");
            setList(response.data)
            setData(response.data)
        } catch (error) {
            console.log(error);
        }
    };
 
    //*** Select/Unselect table rows ***//
    const onMasterCheck = (e) => {
        let tempList = List;
        //*** Check/Uncheck all items
        tempList.map((user) => (user.selected = e.target.checked));
        //*** Update state
        setMasterChecked(e.target.checked);
        setList(tempList);
        setSelectedList(List.filter((e) => e.selected));
    }

    //*** Check table items ***//
    const onItemCheck = (e, item) => {
        let tempList = List;
        tempList.map((user) => {
            if (user.id === item.id) {
                user.selected = e.target.checked;
            }
            return user;
        });

        //*** Control master checkbox state
        const totalItems = List.length;
        const totalCheckedItems = tempList.filter((e) => e.selected).length;

        //*** Update state
        setMasterChecked(totalItems === totalCheckedItems);
        setList(tempList);
        setSelectedList(List.filter((e) => e.selected));
        setItemChecked(true);
        //this.setState({
        //    masterChecked: totalItems === totalCheckedItems,
        //    List: tempList,
        //    selectedList: List.filter((e) => e.selected),
        //});
    }

   
    
    //*** Send broadcast frames to selected robots ***//
    const check_selected_robots_connected_status = () => {
        //*** Subscribe to MQTT topic ***//
        Client_subscribe_to_mqtt_topic();
       // alert("ab0");
        alert(SubscribtionStatus)
        /*if (SubscribtionStatus === true) {*/
           //alert("ab10");
            //*** Get selected list in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
           // let tempList = List;
           // alert("ab11");
            tempList.map((user) => {
                //*** Add and clear interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);
                //alert("ab1")
                Create_broadcasting_command_message(user.robotID); 
            });
        //}
    }
   
    //*** Event to get selected rows(Optional)
    const send_command_to_selected_robots = (command, turn) => {
        //*** Add commands F ,B and S in session variable***// 
        if (command === "F" || command === "B" || command === "S") {
            sessionStorage.removeItem("Command");
            sessionStorage.setItem("Command", command);
        }
        //*** Create previous command and When command = "None" then command="S" ***//
        if (turn === "L" || turn === "R" || turn === "D") {
            if (command === "None" && sessionStorage.getItem("Command") === null) command = "S";
            if (command === "None" && (sessionStorage.getItem("Command") === "F" || sessionStorage.getItem("Command") == "B" || sessionStorage.getItem("Command") == "S")) command = sessionStorage.getItem("Command");
           // alert(command, turn + " from L and R "  );
        }
      
        //*** Subscribe to MQTT topic ***//
        SubscribtionStatus= Client_subscribe_to_mqtt_topic()
        //*** IF We subscribed to topic then we will send frame to robots ***//
       //alert(SubscribtionStatus)
        if (SubscribtionStatus === true) {
            //*** Get Selected List in array ***//
            setSelectedList(List.filter((e) => e.selected))
            let tempList = selectedList;
            tempList.map((user) => {
                //*** Set varibles to null
                Current_command_status = null;
                Current_motor_speed_status = null;
                Current_turn_status = null;
                Current_grass_cutter_status = null;
                Current_sprayer_status = null;

                //*** Add interval of 5 seconds to send frame for multiple robots and we will clear Interval ***//
                const interval = setInterval(() => { }, 5000);
                clearInterval(interval);

                let grassCutterOn = document.getElementById(user.robotID + "-GCOn");
                let grassCutterOff = document.getElementById(user.robotID + "-GCOff");
                if (grassCutterOn.checked) {
                    Current_grass_cutter_status = 1;
                }
                else if (grassCutterOff.checked) {
                    Current_grass_cutter_status = 0;
                }
                let sprayerOn = document.getElementById(user.robotID + "-SPOn");
                let sprayerOff = document.getElementById(user.robotID + "-SPOff");
                if (sprayerOn.checked) {
                    Current_sprayer_status = 1;
                }
                else if (sprayerOff.checked) {
                    Current_sprayer_status = 0;
                }
                Current_command_status = command;
                Current_turn_status = turn;
                if (command === "S") { // when Command Stop then grass cutter and spraying status =0;
                    Current_grass_cutter_status=0;
                    Current_sprayer_status = 0;
                }
                if (Current_turn_status === null || Current_turn_status === '') Current_turn_status = 'D';
                if (Current_motor_speed_status === null || Current_motor_speed_status === '') Current_motor_speed_status = 255;
                if (Current_grass_cutter_status === null || Current_grass_cutter_status === '') Current_grass_cutter_status = 0;
                if (Current_sprayer_status === null || Current_sprayer_status === '') Current_sprayer_status = 0;

                //*** Send command to cloud database ***//
                //const response = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=1&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
                 const resResultAddCommandStatus = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=" + sruserID + "&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");
                 //const resResultAddCommandStatus = Axios("/PlantRobotOperation/addcmd?pid=" + user.plantID + "&uid=" + sruserID + "&rid=" + user.robotID + "&cmd=" + Current_command_status + "&tun=" + Current_turn_status + "&spd=" + Current_motor_speed_status + "&gc=" + Current_grass_cutter_status + "&sp=" + Current_sprayer_status + "&cb=0&mb=0&cid=1");

                //* Get current Command ID *//
                //let resCommandID = Axios("/PlantRobotOperation/getcmdid?pid=" + user.plantID + "&rid=" + user.robotID + "");
                //setCommandID(resCommandID);
                //let datacid = commandID;
                //let command_id;
                //alert("v");
                //datacid.map((datalst) => {
                //    //alert(c.commandID);
                //    command_id = datalst.commandID
                //});
                //alert("v123");
                //alert(command_id);

                //*** Create Json object frame and send it to Robots ***//
                var obj = new Object();
                obj.FID = user.robotID;
                //*** IF command is OFF then we send frame to stop robot operation ***//
                if (command === "OFF") obj.OPN = command
               // alert(Current_command_status);
                //*** IF command is F/B/S then we send frame to robot operation  ***//
                if (command !== "OFF") {
                    obj.Cmd = command;
                    //obj.Cid = user.commandID;
                    obj.Turn = turn;
                    obj.Spd = 255;
                    obj.GC = Current_grass_cutter_status;
                    obj.SP = Current_sprayer_status;
                }
                var command_message = JSON.stringify(obj);
                try {
                    //*** Publish message using MQTT protocol;
                   // Temp 20241021 client1.publish(MQTT_Subscribe_Topic, command_message)
                    send_command_message_to_robot(command_message)
                    console.log(command_message)
                    //setItemChecked(false);
                    //fetchData();
                    //RefreshCommandStatus(); //Updated code to test refresh sending commands on 20231111
                }
                catch (e) {
                    console.log("Step-5 (Command Processing) Error: " + e.message + "");
                }
                //*** Update current command status ***//
                //const response1 = Axios("https://localhost:7248/PlantRobotsInfo?pid=1");
                //setData(response1.data)
            }
            );
           /* fetchData();*/
            //Temp comment on 20231018 RefreshCommandStatus();
        }

    };
    //*** hide camera ***//
    const hideCamera = (dvcam) => {
        document.getElementById(dvcam).style.display = 'none';
    }
    //*** show camera ***//
    const showCamera = (dvcam) => {
        document.getElementById(dvcam).style.display = 'block';
    }
    //*** Stop robots operation ***//
    const stop_selected_robots = () => {
        send_command_to_selected_robots("S", "D");
        //Temp comment on 20231018 RefreshCommandStatus();
    };
    const get_control_panel = (dvcontrolpanel, dvgcsp,  dvmodal) =>
    {
        var divControlPanel = document.getElementById(dvcontrolpanel);
        var divGCSP = document.getElementById(dvgcsp);
        var dvimodal = document.getElementById(dvmodal);

        //if (divControlPanel.style.display === "none") {
        //    divControlPanel.style.display = "block";
        //}
        //else {
        //    divControlPanel.style.display = "none";
        //   // divBlank.style.display = "block";
        //}
       
        //if (divGCSP.style.display === "none") {
        //    divGCSP.style.display = "block";
        //}
        //else {
        //    divGCSP.style.display = "none";
        //}
        //alert("rr123");
        if (dvimodal.style.display === "none") {
            dvimodal.style.display = "block";
            dvimodal.scrollIntoView();
        }
        else {
            dvimodal.style.display = "none";
        }
    };
    function Get_more(dvmodal, robot_id) {
        //List.map((user) => {
        //    if (user.robotID === robot_id) {
        //        user.selected = true;
        //    }
        //});
       // setSelectedList(List);
        var divmodal = document.getElementById(dvmodal);
        if (divmodal.style.display === "none") {
            divmodal.style.display = "block";
            divmodal.scrollIntoView();
         }
         else {
            divmodal.style.display = "none";
         }
    };
    //*** Get single robot operation page ***//
    const get_single_robot_operation = (robotID, plantID) => {
        sessionStorage.setItem("robotID", robotID);
        window.location.href = "/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
        //window.location.href = "http://bhuhit.com/#/PlantRobotOperation?rid=" + robotID + "&pid=" + plantID + "";
    };

    
    //*** React hook to refresh page if any changes, page will auto refresh ***//
    useEffect(() => {
        setPageRefreshStatus(true);
        fetchData();

    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])
   
    //*** Fetch data for multiple robot operation page ***//
    const fetchData = async () => {
        const getrobotname = [];
        var plant_id = sessionStorage.getItem("user_plantid");
       // alert(api.baseURL);
        //alert("hfgjgj");
        let response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        //const response = await Axios("/PlantRobotsOperation?pid=" + plant_id + "&rt=multiple");
        setData(response.data);
        setList(response.data);
        setItemChecked(false);
        for (let i = 0; i < List.length; i++) {
            //alert(List[i].robotName);
            getrobotname.push(List[i].robotName );
           //alert(List.length);
        }
        setGetRobotList(getrobotname);
      //  alert("123");
        //alert(pageRefreshStatus);
        /*if (pageRefreshStatus === true) {*/
            var baseURL = "/#/RobotOperation?pid=" + plant_id + "";
            window.location.href = baseURL;
        //    setPageRefreshStatus(false);
        //}
        //openPage('Operation', 'btnOperation', '#fff')
    }

  //  let tempList = RefreshCommandStatus();
       
   // IPAddress = CaptureCamera();

    function closeFrame() {
        //alert("ok");
        document.getElementById("outerdiv").style.display = "none";
    }
    return (
      <>
          <div>
            <div>
                {/*<h3 style={{ marginTop: "1%", color: "#000" }}>Multiple Robot Operation</h3>*/}
            <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    width:"99%",
                    backgroundColor: "#fff",
                }}
                >
                     <div className="container" style={{ backgroundColor: "#fff" }}>
                           
                        <div className="row">
                            <div className="col-sm-6"
                                    style={{
                                        height: "auto",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                        float: "left",
                                        border: "none",
                                        borderRadius: "5px",
                                        fontSize: "130%",
                                        padding: "1%",
                                    }}
                                    >
                                   Multiple Robot Operations
                                </div>
                                
                                <div className="col-sm-6">
                                    <p></p>
                                    {/*<Multiselect*/}
                                    {/*    options={robots.options}*/}
                                    {/*//    selectedValues={robots.options.selectedValue} // Preselected value to persist in dropdown*/}
                                    {/*   onSelect={onSelect} // Function will trigger on select event*/}
                                    {/*   // onRemove={onRemove} // Function will trigger on remove event*/}
                                    {/*    displayValue="name" // Property name to display in the dropdown options*/}
                                    {/*    showCheckbox="true"*/}
                                    {/*    placeholder="--Select Robots---"*/}
                                    {/*    showArrow="true"*/}
                                    {/*    />*/}
                                    <input
                                        type="text"
                                        placeholder="Search Results"
                                        style={{
                                            width: "40%",
                                            height: "30px",
                                            margin: "10px",
                                            float: "right",
                                           /* border: "none",*/
                                            padding: "1.5%",
                                            borderRadius: "5px",
                                            borderBlockColor: "#c0c0c0",
                                            
                                        }}
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                            </div>
                            </div>
                           
                            <div className="row">
                                <div className="col-6">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={masterChecked}
                                        id="mastercheck"
                                        onChange={(e) => onMasterCheck(e)}
                                    />
                                   &nbsp;Select All
                                </div>

                                <div className="col-2 header-icon-size">
                                    <a onClick={() => check_selected_robots_connected_status()} ><i class="fa fa-cogs operation-icon-1">&nbsp;Connect</i></a>
                                </div>
                                <div className="col-2 header-icon-size">
                                    <a onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch operation-icon-1">&nbsp;Stop Turn</i></a>
                                </div>
                                <div className="col-2 header-icon-size">
                                    <a onClick={() => send_command_to_selected_robots("OFF")}><i class="fa fa-power-off operation-icon-1">&nbsp;Stop</i></a>
                                </div>
                            </div>
                            <div className="grid">
                                {/*<ToggleSwitch></ToggleSwitch>*/}
                                
                                {List.map((user) => {
                                    return [
                                      
                                          <div key={user.robotID}  style={{ backgroundColor: "fff" }}>
                                            {/*<div className="row" id={"dvblank" + user.robotID} style={{ display:"none"}}>*/}
                                            {/*    &nbsp;*/}
                                            {/*</div>*/}
                                            <div style={{float:"right"} }>
                                            <input
                                                type="checkbox"
                                                checked={user.selected}
                                                className="form-check-input"
                                                id="rowcheck{user.id}"
                                                onChange={(e) => onItemCheck(e, user)}
                                            />
                                            </div>
                                            <div className="col container_multi_robots"  style={{ position: 'relative', backgroundColor:"aliceblue", cursor:"pointer"}}>
                                                {user.broadcastStatus === 'Disconnected' ?
                                                    (
                                                        <div className="connection_status"><i class="fa fa-toggle-off" style={{ fontSize: '25px', color: 'red' }}></i></div>
                                                    ) :
                                                    (
                                                        <div className="connection_status"><i class="fas fa-toggle-on" style={{ fontSize: '25px', color: 'lime' }}></i></div>
                                                    )}
                                               
                                               {/* <Iframe key={"Iframe1" + user.robotID} url="http://192.168.0.108:8888/" className='responsive-iframe_1' loading="lazy" allow="microphone;camera" id={'inneriframe'}></Iframe>*/}
                                                {IPAddress && user.robotID === "5" ? (
                                                    <Iframe id={"Iframe1" + user.robotID} key={"Iframe1" + user.robotID} url={IPAddress} className='responsive-iframe_1 inneriframe' loading="lazy" allow="microphone;camera"></Iframe>
                                                )
                                                    : (
                                                      
                                                        <Iframe id={"Iframe2" + user.robotID} key={"Iframe2" + user.robotID} url={bg} className='responsive-iframe_1 inneriframe'  loading="lazy" allow="microphone;camera"></Iframe>
                                                      )
                                                }
                                                &nbsp;
                                                <div style={{ marginTop:"55%", backgroundColor:"aliceblue",  marginLeft:"40%", fontSize:"120%"}}>
                                                    <div id={"dvPL" + user.robotID} class="PowerLight">
                                                      
                                                        {user.currentCommandStatus === "" || user.currentCommandStatus === null || user.currentCommandStatus === "New" ||
                                                            user.currentCommandStatus === "Stop" || user.currentCommandStatus === "S" ||
                                                            user.grassCutter === 'OFF' || user.sprayer === 'OFF' ?
                                                            (
                                                                <div id={"PL" + user.robotID}><PowerLight state={0} /></div>
                                                            )
                                                            :
                                                            (
                                                                <></>
                                                            )
                                                        }

                                                        {user.grassCutter === 'ON' || user.sprayer === 'ON' ?
                                                            (
                                                                <div id={"PL" + user.robotID}><PowerLight state={1} /></div>
                                                            )
                                                            : (
                                                                <></>
                                                            )
                                                        }

                                                        {user.currentCommandStatus === "Moving Forward" || user.currentCommandStatus === "Moving Backward" ||
                                                            user.currentCommandStatus === "Forward Left" || user.currentCommandStatus === "Backward Left" ||
                                                            user.currentCommandStatus === "Forward Right" || user.currentCommandStatus === "Backward Right"
                                                            ?
                                                            (
                                                                <div id={"PL" + user.robotID}><PowerLight state={2} /></div>
                                                            )
                                                            : (
                                                                <></>
                                                            )
                                                        }
                                                        
                                                    </div>
                                                    <div class="display_robot_name">
                                                        {user.robotName}
                                                    </div>

                                                    <div key={user.robotID} id={"dv" + user.robotID} class="control_panel" style={{ display: "none", backgroundColor:"#ffffff"} }>
                                                         <div className="icon-forward-2">
                                                                <a id={"btnStart" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("F", "D")} onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i></a>
                                                         </div>
                                                        <div className="icon-backward-2">
                                                                <a id={"btnReturn" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("B", "D")} onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i></a>
                                                        </div>
                                                        <div className="icon-left">
                                                                <a id={"btnLeft" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left"></i></a>
                                                        </div>
                                                        <div className="icon-right">
                                                                <a id={"btnRight" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right"></i></a>
                                                        </div>
                                                        <div className="icon-turn_stop-2">
                                                                <a id={"btnTurnStop" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o"></i></a>
                                                        </div>
                                                        <div className="icon-forward-right">
                                                                <a id="btnForwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}><i class="fa fa-share"></i></a>
                                                        </div>

                                                        <div className="icon-forward-left">
                                                                <a id="btnForwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "L")}><i class="fa fa-reply"></i></a>
                                                        </div>

                                                        <div className="icon-backward-right-2">
                                                                <a id="btnBackwardRight" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}><i class="fa fa-reply fa-rotate-180"></i></a>
                                                        </div>

                                                        <div className="icon-backward-left-2">
                                                                <a id="btnBackwardLeft" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "L")}><i class="fa fa-share fa-rotate-180"></i></a>
                                                        </div>
                                                      </div>
                                                    <div className="icon-connect_1 icon-size">
                                                        <a id={"btnConnect" + user.robotID} class="operation-icon1" onClick={() => Create_broadcasting_command_message(user.robotID)}><i class="fa fa-cogs icon-size"></i>&nbsp;Connect</a>
                                                    </div>
                                                    
                                                    <div className="icon-control_panel_1 icon-size"  >
                                                        <a id={"btnGetMore" + user.robotID} class="operation-icon1" onClick={() => Get_more("dvmodal" + user.robotID, user.robotID)}><i class="fa fa-ellipsis-h icon-size"></i>&nbsp;More&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                                                    </div>

                                                    <div className="icon-stop-1 icon-size">
                                                        <a id={"btnRobotStop" + user.robotID} class="operation-icon1" onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch"></i>&nbsp;Stop</a>
                                                    </div>

                                                </div>
                                               
                                            </div>
                                           
                                            <div className="row gc-sp-text" id={"dvgcsp" + user.robotID} style={{ display: "block" }}>
                                                {/*<div class="col-2"></div>*/}
                                                <div className="col-sm-6 col_gc_sp">
                                                    GC:&nbsp;
                                                    {
                                                        (itemChecked === false && (user.grassCutter === 'ON')) ? (
                                                            <input
                                                                type="radio"
                                                                id={user.robotID + "-GCOn"}
                                                                name={user.robotID + "-GC"}
                                                                value="1"
                                                                checked="true"
                                                            />
                                                        ) : (
                                                            <input
                                                                type="radio"
                                                                id={user.robotID + "-GCOn"}
                                                                name={user.robotID + "-GC"}
                                                                value="1"
                                                            />
                                                        )
                                                    }
                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                    {(itemChecked === false && (user.grassCutter === "OFF")) ? (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + '-GCOff'}
                                                            name={user.robotID + "-GC"}
                                                            value="0"
                                                            checked="true"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + '-GCOff'}
                                                            name={user.robotID + "-GC"}
                                                            value="0"
                                                        />
                                                    )
                                                    }

                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                </div>

                                                <div className="col-sm-6 col_gc_sp">
                                                    SP:&nbsp;
                                                    {(itemChecked === false && (user.sprayer === 'ON')) ? (
                                                        <input style={{
                                                            color: '#99cc33'
                                                        }}
                                                            type="radio"
                                                            id={user.robotID + "-SPOn"}
                                                            name={user.robotID + "-SP"}
                                                            value="1"
                                                            checked="true"
                                                        />
                                                    ) : (
                                                        <input style={{
                                                            color: '#99cc33'
                                                        }}
                                                            type="radio"
                                                            id={user.robotID + "-SPOn"}
                                                            name={user.robotID + "-SP"}
                                                            value="1"
                                                        />
                                                    )
                                                    }
                                                    <span>&nbsp;On&nbsp;&nbsp;</span>
                                                    {(itemChecked === false && (user.sprayer === 'OFF')) ? (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + "-SPOff"}
                                                            name={user.robotID + "-SP"}
                                                            value="0"
                                                            checked="true"
                                                        />
                                                    ) : (
                                                        <input
                                                            type="radio"
                                                            id={user.robotID + "-SPOff"}
                                                            name={user.robotID + "-SP"}
                                                            value="0"
                                                        />
                                                    )
                                                    }
                                                    <span>&nbsp;Off&nbsp;&nbsp;</span>
                                                </div>
                                            </div>
                                            {/*width: '47%',*/}
                                            {/*top: "25%",*/}
                                            {/*left: "25%",*/}
                                            <div id={"dvmodal" + user.robotID} class="modalcontainer">
                                              
                                                <Modal.Dialog size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                 centered
>
                                                    <Modal.Header closeButton onClick={() => get_control_panel("dv" + user.robotID, "dvgcsp" + user.robotID,  "dvmodal" + user.robotID)}>
                                                        <Modal.Title>
                                                            Robot Operations
                                                        </Modal.Title>
                                                    </Modal.Header>
                                                    <Modal.Body>
                                                        <p>
                                                       
                                                            <div className="col container_multi_robots" style={{ position: 'relative', backgroundColor: "aliceblue" }}>
                                                                {/*<Iframe id={"Iframe3" + user.robotID} key={"Iframe1" + user.robotID} url="http://192.168.1.109:8080/" className='responsive-iframe_1 inneriframe' loading="lazy" allow="microphone;camera"></Iframe>*/}
                                                                {/*<Iframe key={"Iframe1" + user.robotID} url="http://192.168.0.110:8888/" className='responsive-iframe_1' loading="lazy" allow="microphone;camera" id={'inneriframe'}></Iframe>*/}
                                                                {IPAddress && user.robotID === "5" ? (
                                                                    <Iframe id={"Iframe3" + user.robotID} key={"Iframe1" + user.robotID} url={IPAddress} className='responsive-iframe_1 inneriframe' loading="lazy"  allow="microphone;camera"></Iframe>
                                                                )
                                                                : (  
                                                                        //<></>
                                                                          <Iframe id={"Iframe4" + user.robotID} key={"Iframe2" + user.robotID} url={robot_operation} className='responsive-iframe_1 inneriframe' loading="lazy" allow="microphone;camera"></Iframe>
                                                                 )
                                                                }
                                                                &nbsp;
                                                               {/* <div>{Webcam1}</div>*/}
                                                                <div style={{ marginTop: "55%", backgroundColor: "#ffffff", marginLeft: "40%", fontSize: "120%" }}>

                                                                    <div class="display_robot_name">{user.robotName}</div>

                                                                    <div key={"keydv" + user.robotID} id={"dvid" + user.robotID} class="control_panel" style={{ display: "block", backgroundColor: "#ffffff" }}>

                                                                        <div className="icon-forward-2">
                                                                            <a id={"btnStart_1" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("F", "D")} onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up"></i> F</a>
                                                                        </div>

                                                                        <div className="icon-backward-2">
                                                                            <a id={"btnReturn_1" + user.robotID} class="operation-icon" onTouchStart={() => send_command_to_selected_robots("B", "D")} onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down"></i> B</a>
                                                                        </div>

                                                                        <div className="icon-left">
                                                                            <a id={"btnLeft_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left"></i> L</a>
                                                                        </div>

                                                                        <div className="icon-right">
                                                                            <a id={"btnRight_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "R")} >R&nbsp;<i class="fa fa-caret-square-o-right"></i></a>
                                                                        </div>

                                                                        <div className="icon-turn_stop-2">
                                                                            <a id={"btnTurnStop_1" + user.robotID} class="operation-icon" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o"></i>&nbsp;S</a>
                                                                        </div>

                                                                        <div className="icon-forward-right">
                                                                            <a id="btnForwardRight_1" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "R")}>FR&nbsp;<i class="fa fa-share"></i></a>
                                                                        </div>

                                                                        <div className="icon-forward-left">
                                                                            <a id="btnForwardLeft_1" class="operation-icon" onClick={() => send_command_to_selected_robots("F", "L")}><i class="fa fa-reply"></i> FL</a>
                                                                        </div>

                                                                        <div className="icon-backward-right-2">
                                                                            <a id="btnBackwardRight_1" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "R")}>BR&nbsp;<i class="fa fa-reply fa-rotate-180"></i></a>
                                                                        </div>

                                                                        <div className="icon-backward-left-2">
                                                                            <a id="btnBackwardLeft_1" class="operation-icon" onClick={() => send_command_to_selected_robots("B", "L")}><i class="fa fa-share fa-rotate-180"></i> BL</a>
                                                                        </div>

                                                                    </div>

                                                                    <div className="icon-connect_1 modal-icon-size">
                                                                        <a id={"btnConnect_1" + user.robotID} class="modal_connect_stop_link" onClick={() => Create_broadcasting_command_message(user.robotID)} ><i class="fa fa-cogs"></i> Connect</a>
                                                                        {/*<a id={"btnConnect" + user.robotID} class="operation-icon" onClick={() => Create_broadcasting_command_message(user.robotID)} ><i class="fa fa-cogs operation-icon" id="connect-icon_1"></i></a>*/}
                                                                    </div>

                                                                    <div className="icon-stop-1 modal-icon-size">
                                                                        {/* <a id={"btnRobotStop" + user.robotID} class="operation-icon" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch" style={{ color: 'yellow', fontSize: "35px", width: "100%" }}></i></a>*/}
                                                                        <a id={"btnRobotStop_1" + user.robotID} class="modal_connect_stop_link" onClick={() => stop_selected_robots()} ><i class="fa fa-circle-o-notch"></i> Stop</a>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                                                                                 </p>
                                                    </Modal.Body>
                                                    {/*<Modal.Footer>*/}
                                                    {/*    <Button variant="secondary" onClick={() => get_control_panel("dvid" + user.robotID, "dvgcsp1" + user.robotID, "dvblank" + user.robotID, "dvmodal" + user.robotID)}>*/}
                                                    {/*        Close*/}
                                                    {/*    </Button>*/}
                                                    {/*</Modal.Footer>*/}
                                                </Modal.Dialog>
                                            </div> 
                                        </div>
                                    ];
                                    }
                                )
                                }
                            </div>

                            <p></p>
                            <p></p>
                            <div className="row">
                                {/*<div className="col">*/}
                                {/*    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => check_selected_robots_connected_status()} ><i class="fa fa-cogs operation-icon" id="connect-icon"></i>Connect</a>*/}
                                {/*</div>*/}
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnStart" onClick={() => send_command_to_selected_robots("F", "D")} ><i class="fa fa-caret-square-o-up operation-icon" id="forward-icon"></i>Forward</a>
                                </div>
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnReturn" onClick={() => send_command_to_selected_robots("B", "D")} ><i class="fa fa-caret-square-o-down operation-icon" id="backward-icon"></i>Backward</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnLeft" onClick={() => send_command_to_selected_robots("None", "L")} ><i class="fa fa-caret-square-o-left operation-icon" id="left-icon"></i>Left</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnTurnStop" onClick={() => send_command_to_selected_robots("None", "D")} ><i class="fa fa-dot-circle-o operation-icon" id="turn-icon"></i>Stop Turn</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} id="btnRight" onClick={() => send_command_to_selected_robots("None", "R")} ><i class="fa fa-caret-square-o-right operation-icon" id="right-icon"></i>Right</a>
                                </div>
                                
                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => stop_selected_robots()}><i class="fa fa-circle-o-notch operation-icon" id="stop-icon"></i>Stop Robot</a>
                                </div>

                                <div className="col">
                                    <a style={{ cursor: 'Pointer', color: '#A0A0A0' }} onClick={() => send_command_to_selected_robots("OFF")}><i class="fa fa-power-off operation-icon" id="off-icon"></i>Stop</a>
                                </div>

                             </div>
                    </div>
              
                </div>
            </div>
           
          </div>
     

      </>
    );
};

               
