import React from 'react';
//import { Route, Redirect } from 'react-router-dom';
export const hasJWTToken = () => {
    let _isTokenflag = false;
    let accessToken = sessionStorage.getItem("token");
    //if (accessToken === null || accessToken === "null") _isTokenflag = false;
    if (accessToken.length > 4) _isTokenflag = true;
    if (_isTokenflag === false || accessToken === "null") {
        window.location.href = "#/Login";
        return _isTokenflag;
    }
    return _isTokenflag
};
export function RouteGuard ()  {
    return (
        <div>hasJWTToken();</div>
        //<Route {...rest}
        //    render={props => (
        //        hasJWTToken() ?
        //            <Component {...props} />
        //            :
        //            <Route to={{ pathname: '#/login' }} />
        //    )}
        ///>
    );
};

export default RouteGuard;