//*** Import React Library
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Table from "react-data-table-component";
import Button from "react-bootstrap/Button";
import Axios from 'axios'
import "../../Assets/Styles/robot.css";
import { Route, Routes } from 'react-router-dom';
import { hasJWTToken } from "../Account/RouteGuard";
//*** Create columns for react table
const columns = [
    { name: "Date", selector: (row) => row.workingDate},
    { name: "Time (HH:MM:SS)", selector: (row) => row.workingTime, sortable: true, },
  /*  { name: "Command ID", selector: (row) => row.commandID, sortable: true },*/
    { name: "Command", selector: (row) => row.currentCommandStatus, sortable: true },
    { name: "Robot", selector: (row) => row.robotName, sortable: true },
    { name: "Block", selector: (row) => row.blockName, sortable: true },
    { name: "Row", selector: (row) => row.rowName, sortable: true },
    { name: "Speed", selector: (row) => row.speed, sortable: true },
    { name: "GC", selector: (row) => row.grassCutter, sortable: true },
    { name: "SP", selector: (row) => row.sprayer, sortable: true },
];

//*** Create custom style for react table
const customStyles = {
    table: {
        style: {
            border: "none",
            fontSize: "100%"
        },
    },
    header: {
        backgroundColor: "transparent",
    },
    rows: {
        style: {
            backgroundColor: "transparent",
        },
    },
    headRow: {
        style: {
            backgroundColor: "transparent",
            fontSize: "110%"
        },
    },
    pagination: {
        style: {
            backgroundColor: "transparent",
            fontWeight: "600",
        },
    },
    headCells: {
        style: {
            borderBottom: "none",
            fontWeight: "700",
            fontSize: "110%"
        },
    },
};

export const CompletedCommandsStatus = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [data, setData] = useState([])
    const [List, setList] = useState([])
    const [SelectedList, setSelectedList] = useState([])
    const [MasterChecked, setMasterChecked] = useState(false)

 //*** Seach Textbox text change event
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        let tempList = data;
        const filteredData = tempList.filter((event) =>
            Object.values(event).some(
                (value) => typeof value === "string" && value.includes(searchTerm)
            )
        );
        if (event.target.value != null) {
            setList(filteredData);
        }
        if (event.target.value == "") {
            setList(data);
        }
    };

 //*** Filter table
    const filteredData = List.filter((event) =>
        Object.values(event).some(
            (value) => typeof value === "string" && value.includes(searchTerm)
        )
    );

    //const handleRowSelected = (rows) => {
    //    setData(rows.selectedRows);
    //};
    //const contextActions = (
    //    <button onClick={() => alert(data.map((r) => r.robotID))}>
    //        Show Selected Rows
    //    </button>
    //);

 //*** Use React hooks - if occur any changes on page then useffect() function will run auto
    useEffect(() => {
        fetchData();
    }, [])
    useEffect(() => {
        console.log(data)
    }, [data])

 //*** Fetch data from API
    const fetchData = async () => {
        var plant_id = sessionStorage.getItem("user_plantid");
        //const response = await Axios("/PlantRobotsOperationStatus?pid=" + plant_id + "&rid=0&rt=1&rbt=0&ct=2&uid=1");
       //const response = await Axios("/CompletedCommandStatus?pid=" + plant_id + "");
        const response = await Axios("/PlantRobotsOperationStatus?pid=" + plant_id + "&rid=0&rt=1&rbt=0&ct=2&uid=1");
        setData(response.data)
        setList(response.data)
    }

 // *** Return html code 
    return (
        <>
            {hasJWTToken() ? (
               <div>
                 <h3 style={{ margin: "2%", marginLeft:"2%" }}>Completed Commands Status</h3>
                 <div
                style={{
                    border: "1px solid #c0c0c0",
                    borderRadius: "5px",
                    margin: "2%",
                    backgroundColor: "#fff",
                }}
                >
                <div className="container dvscroll" style={{ width: "100%", height: "70%", fontSize: "92%" }} >
                    <div className="row dvscroll">
                        <div className="col-sm-12">
                            <input
                                type="text"
                                placeholder="Search Results"
                                style={{
                                    width: "20%",
                                    height: "30px",
                                    margin: "10px",
                                    float: "right",
                                    border: "none",
                                    padding: "1.5%",
                                    borderRadius: "5px",
                                }}
                                value={searchTerm}
                                onChange={handleSearch}
                            />
                            <p></p>
                            <DataTable className="table .table-sm table-hover" sort={true} pagination={true}
                                search={true}
                                columns={columns}
                                data={filteredData}
                                highlightOnHover={true}
                                customStyles={customStyles}
                            >
                            </DataTable>
                        </div>
                    </div>
                </div>
                    </div>
               </div>
            ) :
                (
                    <Route to={{ pathname: '#/login' }} />
                )
            }
        </>
    );
};
