import React, { useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, useLoadScript, Polyline, DirectionsRenderer } from "@react-google-maps/api";
import axios from 'axios';

export function DistanceCalculator() {
    const [distance, setDistance] = useState(null);

    const calculateDistance = async () => {
        const apiKey = 'AIzaSyDmmBMzFbZmGwc3FMR90OQNy8K3WFFK9eU';
        const origin = '37.7749,-122.4194'; // San Francisco
        const destination = '34.0522,-118.2437'; // Los Angeles
        var nyc = new GoogleMap.maps.LatLng(40.715, -74.002);
        var london = new GoogleMap.maps.LatLng(51.506, -0.119);
        var distance = GoogleMap.maps.geometry.spherical.computeDistanceBetween(nyc, london)
        alert(distance);

        alert(GoogleMap.maps.geometry.spherical.computeDistanceBetween(origin, destination));
        //const url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}';
        alert("gh");
        //try {
        //    const response = await axios.get(url);
        //    alert("gh123");
        //    const result = response.data;
        //    alert("gh123456");
        //    const distanceInMeters = result.rows[0].elements[0].distance.value;
        //    alert(distanceInMeters);
        //    setDistance(distanceInMeters);
        //} catch (error) {
        //    console.error('Error fetching data: ', error);
        //}
    };

    return (
        <div>
            <h2>Distance Calculator</h2>
            <button onClick={calculateDistance}>Calculate Distance</button>
            {distance && <p>Distance: {distance} meters</p>}
        </div>
    );
};

export default DistanceCalculator;
