import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter , BrowserRouter, Routes, Route} from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Layout } from './components/Shared/Layout';
import { Login } from './components/Account/Login';
//import { RobotsOperation } from "../src/components/RobotOperation/RobotsOperation";
//import { RobotLocation } from "./components/RobotOperation/RobotLocation";
//import { Login } from '../src/components/Account/UserLogin';
const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
//alert(baseUrl + "hi");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <HashRouter basename={baseUrl}>
          <App />
    </HashRouter>


  //<BrowserRouter>
  //        <App />
  //</BrowserRouter>
  //  <BrowserRouter>
  //      <Routes>
  //             <Route path="/" element={<Layout />}>
  //              <Route index path="Login" element={<UserLogin />} />
  //              {/*<Route path="RobotsOperation" element={<RobotsOperation/>} />*/}
  //              {/*<Route path="RobotLocation" element={<RobotLocation />} />*/}
  //              {/*<Route path="*" element={<NoPage />} />*/}
  //          </Route>
  //          </Routes>
  //</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//Temp comment on 20231101 reportWebVitals();
