import React, { Component } from 'react';
export class Home extends Component {
  static displayName = Home.name;
  render() {
      return (
     <div class="container marginleftright">
         <div class="row rowbox">
            <div class="col">
                <h4>Bhuhit - Operating, Monitoring & Reporting Application on Energy Guru</h4>
                <p>Bhuhit is a grass-cutting, Spraying, Sowing, haversting system for farms, industries, hospitals, airports, schools, hotel rooms and
                   malls developed by Energy Guru. This is a comprehensive application to manage soile care operations using Bhuhit, built on Energy-Guru platform. From the application, all devices can be remotely controlled and monitored. Reports can be generated as needed to measure efficacy. If you need any assistance, please contact Energy Guru team at contact[at]energy-guru[.]com
                </p>
            </div>
         </div>
         {/* <div class="col"></div>*/}
         <div class="row rowbox">
               <div class="col">
                <h4>Business Intelligence and Dashboards on Energy Guru</h4>
                <p>Energy Guru (Towards Truth) is a dashboard and business intelligence reporting platform that empowers users to gain insights and perform analyses quickly.</p>
                <p>It provides excellent intuitive user interface and graph capabilities.</p>
                <p>Over 50 data sources can be integrated to produce custom dashboards and reports.</p>
                <p>It is used as one source of truth and can be shared with team and clients. The platform is highly scalable and customizable.</p>
                <p>Please contact us support[at]leveragenet.us for further details.</p>
               </div>
               {/*<div class="col"></div>*/}
              </div>
     </div>
    );
  }
}
