import ReactDOM from 'react-dom';
//import Toggle from 'react-bootstrap-toggle';
import React, { useEffect, useState, Component } from "react";
export function ToggleSwitch() {
    const [isChecked, setToggleActive] = useState(false);
    const handleChange= (e) =>{
        //this.setState({ isChecked: e.target.checked });
        setToggleActive(e.target.checked);
    };
   const toggleChecked=  () =>{
       // this.setState({ isChecked: !this.state.isChecked });
       setToggleActive({ isChecked: !isChecked });
    };

    return (
        <div className="switch">
            <span>
                <input
                    type="checkbox"
                    id="toggleInput"
                    ref="toggleInput"
                    checked={isChecked}
                    
                />
               
                <button
                    className="slider"
                    type="button"
                />
                    {/*onChange={handleChange.bind(this)}*/}
                {/* onClick={toggleChecked.bind(this)}>*/}
                onClick={toggleChecked.bind(this)}
            </span>
            <label
                for="toggleInput"
                >
                Your label here
                {/* Change to {this.props.title} and you can set the label text in a higher level component */}
            </label>
        </div>
    );
}
export default ToggleSwitch;

