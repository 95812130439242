import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
    {
        title: "Dashboard",
        path: "/Dashboard",
        //path: "",
        /*icon: <AiIcons.AiFillHome/>,*/
        icon:<FaIcons.FaPalette/>,
    },
    {
        title: "Account",
        path: "/account",
        icon: <FaIcons.FaKey />,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Add Company",
                path: "/account/company",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Company",
                path: "/account/searchcompany",
                icon: <FaIcons.FaSearch/>,
            },
            {
                title: "Add User",
                path: "/account/user",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Users",
                path: "/account/serchusers",
                icon: <FaIcons.FaSearch/>,
            },
        ],
    },
    {
        title: "Equipment Setup",
      /*  path:"/PlantEquipmentSetup/plantequipmentsetup",*/
        path: "",
        icon: <FaIcons.FaRegListAlt/>,
        iconClosed: <RiIcons.RiArrowDownSFill/>,
        iconOpened: <RiIcons.RiArrowUpSFill/>,
        subNav: [
            {
                title: "Company Plant",
                /* path: "/plantequipmentsetup/companyplant",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Plants",
                /* path: "/plantequipmentsetup/serchplants",*/
                path: "",
                icon: <FaIcons.FaSearch/>,
            },
            {
                title: "Plant Blocks",
                /* path: "/plantequipmentsetup/plantblocks",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Blocks",
                /* path: "/plantequipmentsetup/serchblocks",*/
                path: "",
                icon: <FaIcons.FaSearch/>,
            },
            {
                title: "Plant Rows",
                /*path: "/plantequipmentsetup/plantrows",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Rows",
               ///* path: "/plantequipmentsetup/searchrows",*/
                path: "",
                icon: <FaIcons.FaSearch/>,
            },
            {
                title: "Plant Controllers",
                /* path: "/plantequipmentsetup/plantcontrollers",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Controllers",
                /* path: "/plantequipmentsetup/serchcontrollers",*/
                path: "",
                icon: <FaIcons.FaSearch/>,
            },
            {
                title: "Plant Robots",
                /*path: "/plantequipmentsetup/plantrobots",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
            {
                title: "Search Robots",
                /*  path: "/plantequipmentsetup/serchrobots",*/
                path: "",
                icon: <FaIcons.FaSearch/>,
            },
        ],
    },
    {
        title: "Equipment Linking",
        /* path: "/equipmentlinkingdetails",*/
        path: "",
        icon: <FaIcons.FaLink/>,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Block Rows",
                /* path: "/plantequipmentsetup/blockrows",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
            {
                title: "Search Block Rows",
                /* path: "/plantequipmentsetup/serchblockrows",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
            {
                title: "Block Controllers",
               /* path: "/plantequipmentsetup/blockcontrollers",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
            {
                title: "Search Block Controllers",
                /*path: "/plantequipmentsetup/serchblockcontrollers",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
            {
                title: "Block Robots",
                /* path: "/plantequipmentsetup/blockrobots",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
            {
                title: "Search Block Robots",
                /* path: "/plantequipmentsetup/searchblockrobots",*/
                path: "",
                icon: <FaIcons.FaLink />,
            },
           
        ],
    },
    {
        title: "Operation",
        /* path: "/robotop1",*/
        path: "",
        icon: <AiIcons.AiFillCloud />,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: "Plant Robot Operation",
                path: "/PlantRobotOperation?rid=5&pid=1" ,
                icon: <FaIcons.FaRobot/>,
                cName: "sub-nav",
            },
            {
                title: "Robot Operation",
                path: "/RobotsOperation?pid=1",
                icon: < FaIcons.FaRobot />,
                cName: "sub-nav",
            },
            {
                title: "Robots Operation",
                path: "/RobotOperation",
                icon: < FaIcons.FaRobot />,
                cName: "sub-nav",
            },
            {
                title: "Operation Scheduling",
                /* path: "/operation/ros",*/
                path: "",
                icon: <FaIcons.FaRegEdit/>,
            },
        ],
    },
    {
        title: "Monitoring",
        /*path: "/monitoring",*/
        path: "",
        icon: <AiIcons.AiTwotoneEye/>,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                title: "Current Command Status",
                path: "/CurrentCommandStatus",
                icon: <AiIcons.AiTwotoneEye/>,
                cName: "sub-nav",
            },
            {
                title: "Robot Operation Status",
                path: "/PlantRobotsOpnStatus",
                icon: <AiIcons.AiTwotoneEye/>,
                cName: "sub-nav",
            },
            {
                title: "Pending Commands",
                path: "/PendingCommandsStatus",
                icon: <AiIcons.AiTwotoneEye/>,
                cName: "sub-nav",
            },
            {
                title: "Completed Commands",
                path: "/CompletedCommandsStatus",
                icon: <AiIcons.AiTwotoneEye/>,
                cName: "sub-nav",
            },
            {
                title: "User Logged Status",
                /* path: "/monitoring/userloggedstatus",*/
                path: "",
                icon: <AiIcons.AiTwotoneEye/>,
            },
            {
                title: "Robot Connected Status",
                path: "/RobotsBroadcastStatus",
                icon: <AiIcons.AiTwotoneEye/>,
            },
            {
                title: "Current Plant Level Grass Cutting",
                path: "/CurrentPlantLevelGrassCutting",
                //path: "",
                icon: <AiIcons.AiTwotoneEye/>,
            },
            {
                title: "Current Plant Level Spraying",
                path: "/CurrentPlantLevelSpraying",
                //path: "",
                icon: <AiIcons.AiTwotoneEye/>,
            },
            {
                title: "Current Robot Level Grass Cutting",
                path: "/CurrentPlantLevelGrassCutting",
                //path: "",
                icon: <AiIcons.AiTwotoneEye/>,
            },
            {
                title: "Current Robot Level Spraying",
                path: "/CurrentPlantLevelSpraying",
                //path: "",
                icon: <AiIcons.AiTwotoneEye/>,
            },
        ],
    },
    {
        title: "Reports",
        /* path: "/reports",*/
        path: "",
        icon: <AiIcons.AiOutlineBarChart/>,
        iconClosed: <RiIcons.RiArrowDownSFill />,
        iconOpened: <RiIcons.RiArrowUpSFill />,

        subNav: [
            {
                title: "Plant Level Grass Operation",
                path: "/reports/plantleveloperation",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Block Level Operation",
                path: "/reports/blockleveloperation",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Robot Level Operation",
                path: "/reports/robotleveloperation",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
            },
            {
                title: "Plant Level Grass Cutting",
                path: "#/CurrentPlantsLevelGrassCutting",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Block Level Grasscutting",
                path: "/reports/blocklevelgrasscutting",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Robot Level Grasscutting",
                path: "#/CurrentRobotsLevelGrassCutting",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
            },
            {
                title: "Plant Level Spraying",
                path: "#/CurrentRobotLevelSpraying",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Block Level Spraying",
                path: "/reports/blocklevelspraying",
               //path: "",
                icon: <FaIcons.FaNewspaper/>,
                cName: "sub-nav",
            },
            {
                title: "Robot Level Spraying",
                path: "#/CurrentRobotLevelSpraying",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
            },
            {
                title: "Month Level Grass Cutting",
                path: "#/MonthLevelGrassCutting",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
            },
            {
                title: "Month Level Spraying",
                path: "#/MonthLevelSpraying",
                //path: "",
                icon: <FaIcons.FaNewspaper/>,
            },
        ],
    },
    {
        title: "Robot Location",
        path: "/RobotLocation",
        icon: <AiIcons.AiTwotoneEnvironment />,
    },
    {
        title: "Weather Status",
        /*  path: "/Infobox",*/
        path: "",
        icon: <AiIcons.AiTwotoneMail/>,
    }
];