//*** import react library and components
import React, { useRef, useState } from "react";
import { Component } from "react";
import "../../Assets/Styles/Joystick.css";
import {Create_mqtt_command_message} from "./PlantRobotOperation";
class Joystick1 extends Component {
       constructor(props, container, options = {}) {
        super(props);
        this.container = container;
        this.options = Object.assign(
            {
                width:140,
                height:140,
                color: 'black',
                handleColor: 'white',
                handleRadius:35,
                onChange: null,
            },
            options
        );
      
        //this.joystick = document.createElement('div');
        //this.joystick.style.width = `${this.options.width}px`;
        //this.joystick.style.height = `${this.options.height}px`;
        //this.joystick.style.borderRadius = '50%';
        //this.joystick.style.backgroundColor = this.options.color;
        //this.joystick.style.position = 'relative';
        //this.joystick.style.touchAction = 'none';
        //this.joystick.style.border = '3px solid black';
        //this.joystick.style.opacity = '50%';

        // container.appendChild(this.joystick);
        //this.handle = document.createElement('div');
        //this.handle.style.width = `${this.options.handleRadius * 2}px`;
        //this.handle.style.height = `${this.options.handleRadius * 2}px`;
        //this.handle.style.borderRadius = '50%';
        //this.handle.style.backgroundColor = this.options.handleColor;
        //this.handle.style.position = 'absolute';
        //this.handle.style.top = `${this.options.height / 2 - this.options.handleRadius}px`;
        //this.handle.style.left = `${this.options.width / 2 - this.options.handleRadius}px`;
        //this.handle.style.touchAction = 'none';
       
       this.joystick = document.getElementById("joystick");
       this.handle = document.getElementById("handle");
      // this.joystick.appendChild(this.handle);
        this.position = { x: 0, y: 0 };
        this.delta = { x: 0, y: 0 };
        this.handleRadius = '35px'; //this.options.handleRadius;
        //this.joystick = document.getElementById("joystick");
        //this.joystickRect = this.joystick.getBoundingClientRect();
        this.isPressed = false;
        this.touchId = null;
        this._addEventListeners();
    }
    _addEventListeners =() => {
        //onMouseDown = { handleMouseDown }
        //onMouseUp = { handleMouseUp }
        //onMouseMove = { handleMove }
        //onTouchMove = { handleMove }
        //onTouchStart = { handleTouchStart }
        //onTouchEnd = { handleTouchEnd }
       // let handle = document.getElementById("handle");
      // this.handle.addEventListener('mousedown', this._handleMouseDown.bind(this));
        document.addEventListener('onMouseMove', this._handleMouseMove.bind(this));
       document.addEventListener('onMouseUp', this._handleMouseUp.bind(this));
     // this.handle.addEventListener('touchstart', this._handleTouchStart.bind(this));
        document.addEventListener('onTouchMove', this._handleTouchMove.bind(this));
        document.addEventListener('onTouchEnd', this._handleTouchEnd.bind(this));
       // document.addEventListener('touchmove', this._handleTouchMove.bind(this));
    }
  
    _handleMouseDown = (event) => {
        //alert("ok");
        this.isPressed = true;
       // alert("ok111");
        this._updatePosition(event.clientX, event.clientY);
       // alert("ok11134");
    }

    _handleMouseMove = (event) => {
        //alert("move-ok");
        if (this.isPressed) {
            this._updatePosition(event.clientX, event.clientY);
        }
    }

    _handleMouseUp =() => {
       // alert(" up ok");
        this.isPressed = false;
        this._resetPosition();
    }

    _handleTouchStart =(event) =>{
        if (this.touchId === null) {
            this.touchId = event.changedTouches[0].identifier;
            this.isPressed = true;
            this._updatePosition(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
        }
    }

    _handleTouchEnd =(event) => {
        for (let i = 0; i < event.changedTouches.length; i++) {
            if (event.changedTouches[i].identifier === this.touchId) {
                this.touchId = null;
                this.isPressed = false;
                this._resetPosition();
                break;
            }
        }
    }

    _handleTouchMove =(event) => {
        for (let i = 0; i < event.changedTouches.length; i++) {
            if (event.changedTouches[i].identifier === this.touchId) {
                this._updatePosition(event.changedTouches[i].clientX, event.changedTouches[i].clientY);
                break;
            }
        }
    }

    _updatePosition =(x, y) => {
        let clientX = x;
        let clientY = y;
      //  alert("ok-222");
       //this.joystick = document.getElementById("joystick");
       this.joystickRect = this.joystick.getBoundingClientRect();
       // alert("ok-1");
        if (this.touchId !== null) {
            for (let i = 0; i < window.event.changedTouches.length; i++) {
                if (window.event.changedTouches[i].identifier === this.touchId) {
                    clientX = window.event.changedTouches[i].clientX;
                    clientY = window.event.changedTouches[i].clientY;
                    const dx = x - this.joystickRect.left - 70 + 40; //this.options.width / 2 + this.options.handleRadius;
                    const dy = y - this.joystickRect.top - 70 + 40; //this.options.height / 2 + this.options.handleRadius;

                    break;
                }
            }
        }

        const dx = clientX - this.joystickRect.left - 70; //this.options.width / 2;
        const dy = clientY - this.joystickRect.top - 70;//this.options.height / 2;
       // alert("ok-2");
       // alert(dx);
        const distance = Math.sqrt(dx * dx + dy * dy);
        //alert(distance);
        if (distance > 35) {
            const angle = Math.atan2(dy, dx);
            this.position = { x: Math.cos(angle) * 35, y: Math.sin(angle) * 35 };
           // alert("ok-3" + this.position);
        } else {
            this.position = { x: dx, y: dy };
            //alert("ok-4" + this.position);
        }
        var angle1 = (Math.atan2(dy, dx));   
         var x1=(Math.cos(angle1) * 40);
        var y1 = (Math.sin(angle1) * 40);
        alert(x1 + ", " + y1);
        this.delta = { x: this.position.x / 40, y: this.position.y / 40};
        //alert(this.position.x);
        this.handle1 = document.getElementById("handle");
        this.handle1.style.transform = `translate(${this.position.x}px, ${this.position.y}px)`;
        //alert("all right12341");

        if (typeof this.options.onChange === 'function') {
            this.options.onChange(this.delta);
        }
    }

    _resetPosition = () => {
        //this.handle1 = document.getElementById("handle");
        this.position = { x: 0, y: 0 };
        this.delta = { x: 0, y: 0 };
        this.handle.style.transform = `translate(0, 0)`;

        if (typeof this.options.onChange === 'function') {
            this.options.onChange(this.delta);
        }
    }
    render() {
        return (
        /*<h1>Hello, world!</h1>*/
            <div id="joystick" 
                style={{
                    width: "140px",
                    height: "140px",
                    borderRadius: "50%",
                    backgroundColor: "#000",
                    opacity: 0.5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}
                onMouseUp={this._handleMouseUp}
                onMouseMove={this._handleMouseMove}
                onTouchMove={this._handleTouchMove}
                onTouchEnd={this._handleTouchEnd}
            >   
                <div id="handle" style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    backgroundColor: "#fff",
                    opacity: 1,
                    position: "absolute",
                    top: `${this.position.y * 70 + 35}px`,
                    left: `${this.position.x * 70 + 35}px`,
                }}
                    onMouseDown={this._handleMouseDown}
                    onTouchStart={this._handleTouchStart}
                   
                >
                </div>
            </div>

            /*<iframe src="https://fielderbot.com/Dashboard/GrassCutter" width={1000} height={450} loading="lazy" allow="microphone;camera"></iframe>*/
        );
    }
}
export default Joystick1

