import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from '../NavMenu';
import Sidebar from "../Shared/Sidebar";
import Footer from "../Shared/Footer";
/*import UserLogin from "../Account/UserLogin";*/
export class Layout extends Component {
  static displayName = Layout.name;
  render() {
    return (
      <div>
        {/*<NavMenu />*/}
        <Sidebar/>
       {/* <UserLogin/>*/}
        <Container>
          {this.props.children}
        </Container>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <Footer />

      </div>
    );
  }
}
