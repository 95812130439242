//*** import react library  and components
import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { CurrentPlantLevelGrassCutting } from "../Monitoring/CurrentPlantLevelGrassCutting";
import { CurrentPlantLevelSpraying } from "../Monitoring/CurrentPlantLevelSpraying";
import { ChartCurrentPlantLevelGrassCutting } from "../Monitoring/ChartCurrentPlantLevelGrassCutting";
import { ChartCurrentGrassCuttingSpraying } from "../Monitoring/ChartCurrentGrassCuttingSpraying";
import { ChartGrassCuttingByMonths } from "../Monitoring/ChartGrassCuttingByMonths";
import { ChartGrassCuttingByHours } from "../Monitoring/ChartGrassCuttingByHours";
import { ChartCurrentPlantLevelSpraying } from "../Monitoring/ChartCurrentPlantLevelSpraying";
import { ChartSprayingByMonths } from "../Monitoring/ChartSprayingByMonths";
import { ChartSprayingByHours } from "../Monitoring/ChartSprayingByHours";
import { CurrentRobotLevelGrassCutting } from "../Monitoring/CurrentRobotLevelGrassCutting";
import { ChartCurrentRobotLevelGrassCutting } from "../Monitoring/ChartCurrentRobotLevelGrassCutting";
import { CurrentRobotLevelSpraying } from "../Monitoring/CurrentRobotLevelSpraying";
import { ChartCurrentRobotLevelSpraying } from "../Monitoring/ChartCurrentRobotLevelSpraying"; 
import { CurrentCommandStatus } from "../Monitoring/CurrentCommandStatus";
import { PlantRobotsBroadcastStatus } from "../Monitoring/PlantRobotsBroadcastStatus";
import { RobotLocation } from "../RobotOperation/RobotLocation";
import { hasJWTToken } from "../Account/RouteGuard";
import "../../Assets/Styles/dashboard.css";
import { Route, Routes } from 'react-router-dom';
//import { LineChart } from "../RobotOperation/LineChart";
export const Dashboard = () => {
    //function hasJWTToken() {
    //    let _isTokenflag = false;
    //    let accessToken = sessionStorage.getItem("token");
    //    // let accessToken1 = JSON.parse(accessToken);
    //    //check user has JWT token
    //    (accessToken !== "null") ? _isTokenflag = true : _isTokenflag = false;
    //    if (!_isTokenflag) {
    //        window.location.href = "#/Login";
    //        return _isTokenflag;
    //    }
    //    return _isTokenflag
    //}
    return (
        <>
           
        {hasJWTToken() ? (
       
        <div className="services1">
            
            {/* <h1>Dashboard</h1>*/}
            <p></p>
            <div class="card-list">
                <div class="row">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <div class="card blue">
                            <div class="title">Grass Cutting</div>
                            <i class="zmdi zmdi-upload"></i>
                            <div class="value">1530 Meter</div>
                            <div class="stat"><b>10</b>% increase</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <div class="card green">
                            <div class="title">Spraying</div>
                            <i class="zmdi zmdi-upload"></i>
                            <div class="value">1640 Meter</div>
                            <div class="stat"><b>15</b>% increase</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <div class="card orange">
                            <div class="title">Sowing</div>
                            <i class="zmdi zmdi-download"></i>
                            <div class="value">1400 Meter</div>
                            <div class="stat"><b>13</b>% increase</div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3 mb-4">
                        <div class="card red">
                            <div class="title">Harvesting</div>
                            <i class="zmdi zmdi-download"></i>
                            <div class="value">1330 Meter</div>
                            <div class="stat"><b>5</b>% decrease</div>
                        </div>
                    </div>
                </div>
            </div> 
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }} >
                    <ChartCurrentRobotLevelGrassCutting></ChartCurrentRobotLevelGrassCutting>
                </div>
                <div className="column" style={{ width: "50%" }}>
                    <ChartCurrentRobotLevelSpraying></ChartCurrentRobotLevelSpraying>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }} >
                    <ChartGrassCuttingByMonths></ChartGrassCuttingByMonths>
                </div>
                <div className="column" style={{ width: "50%" }}>
                    <ChartSprayingByMonths></ChartSprayingByMonths>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }} >
                    <ChartGrassCuttingByHours></ChartGrassCuttingByHours>
                </div>
                <div className="column" style={{ width: "50%" }}>
                    <ChartSprayingByHours></ChartSprayingByHours>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }} >
                    <ChartCurrentPlantLevelGrassCutting></ChartCurrentPlantLevelGrassCutting>
                </div>
                <div className="column" style={{ width: "50%" }}>
                    <ChartCurrentPlantLevelSpraying></ChartCurrentPlantLevelSpraying>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%",height:"30%" }} >
                    <ChartCurrentGrassCuttingSpraying></ChartCurrentGrassCuttingSpraying>
                </div>
                <div className="column" style={{ width: "50%" }}>
                    <ChartCurrentPlantLevelSpraying></ChartCurrentPlantLevelSpraying>
                </div>
            </div>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }}>
                    <CurrentCommandStatus></CurrentCommandStatus>
                </div>
                <div className="column" style={{width:"50%"}}>
                    <PlantRobotsBroadcastStatus></PlantRobotsBroadcastStatus>
                </div>
            </div>
            <p></p>
            <div className="row" style={{ width: "100%" }}>
                <div className="column" style={{ width: "50%" }}>
                     <RobotLocation></RobotLocation>
                </div>
            </div>
        </div>
            ) :
            (
                <Route to={{ pathname: '#/login' }} />
            )
            }
        </>
    );
}
    
    