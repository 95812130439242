import React, { Component } from 'react';
import "../../Assets/Styles/footer.css";
import 'bootstrap/dist/css/bootstrap.css';
class Footer extends Component {
  render () {
      return (
          <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between py-2">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"> Copyright ©
                      <a href="https://.bhuhit.com/" target="_blank" rel="noopener noreferrer"></a>2024 Bhuhit</span><div>&nbsp;&nbsp;<i className="fa fa-envelope" style={{ color: '#99cc33' }}></i>&nbsp;<a emailto='contact@energyguru.com'>contact@energy-guru.com</a> &nbsp; &nbsp;<i className="fa fa-phone" style={{ color: '#99cc33' }}></i>&nbsp; (+91) 99703 19054 &nbsp;</div> 
            </div>
        </footer> 
    );
  }
}
export default Footer;